import { getCenterAny } from "../utils/maps.js";
import { asyncMapArray } from "../utils/tools.js";
export class ARouteMapHelperService {
    constructor() {
        this.Polygons = [];
        this.PolygonsLower = [];
        this.waySegments = [];
        this.waySegmentsThick = [];
        this.NumberMarkers = [];
    }
    drawWaySegment_new(map, geoObject, color, zIndex, strokeWeight) {
        if (!geoObject || !geoObject.Geo || !geoObject.Geo.type)
            return;
        switch (geoObject.Geo.type) {
            case "Point": {
                const [lng, lat] = geoObject.Geo.coordinates;
                let mapsGeo = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng)
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        geoType: geoObject.Geo.type
                    }
                });
                mapsGeo.setMap(map);
                this.waySegments.push(mapsGeo);
                return;
            }
            case "LineString": {
                let waySegmentBounds = geoObject.Geo.coordinates;
                let path = [];
                let center = { lat: 0, lng: 0 };
                for (let p = 0; p < waySegmentBounds.length; p++) {
                    let latlng = {
                        lat: waySegmentBounds[p][1],
                        lng: waySegmentBounds[p][0]
                    };
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                    path.push(latlng);
                }
                center.lat /= waySegmentBounds.length;
                center.lng /= waySegmentBounds.length;
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polyline({
                    path: path,
                    strokeColor: color.hexi,
                    strokeOpacity: 1.0,
                    strokeWeight: strokeWeight,
                    zIndex: zIndex
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        geoType: geoObject.Geo.type
                    }
                });
                mapsGeo.setMap(map);
                this.waySegments.push(mapsGeo);
                return;
            }
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    drawWaySegmentThick(map, geoObject, color) {
        if (!geoObject || !geoObject.Geo || !geoObject.Geo.type)
            return;
        switch (geoObject.Geo.type) {
            case "Point": {
                const [lng, lat] = geoObject.Geo.coordinates;
                let mapsGeo = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng)
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        geoType: geoObject.Geo.type
                    }
                });
                mapsGeo.setMap(map);
                this.waySegmentsThick.push(mapsGeo);
                return;
            }
            case "LineString": {
                let waySegmentBounds = geoObject.Geo.coordinates;
                let path = [];
                let center = { lat: 0, lng: 0 };
                for (let p = 0; p < waySegmentBounds.length; p++) {
                    let latlng = {
                        lat: waySegmentBounds[p][1],
                        lng: waySegmentBounds[p][0]
                    };
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                    path.push(latlng);
                }
                center.lat /= waySegmentBounds.length;
                center.lng /= waySegmentBounds.length;
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polyline({
                    path: path,
                    strokeColor: color.hexi,
                    strokeOpacity: 0.5,
                    strokeWeight: 15,
                    zIndex: 3.
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        geoType: geoObject.Geo.type
                    }
                });
                mapsGeo.setMap(map);
                this.waySegmentsThick.push(mapsGeo);
                return;
            }
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    drawPolygonOnMap(map, geoObject, type, id, color, strokeColor, zIndex, clickEvent, mouseOverEvent, mouseOutEvent) {
        if (!geoObject || !geoObject.Geo || !geoObject.Geo.type)
            return;
        let mapsGeo;
        switch (geoObject.Geo.type) {
            case "Polygon": {
                let PolygonBounds = geoObject.Geo.coordinates;
                let parkingSpacePaths = [];
                let center = { lat: 0, lng: 0 };
                for (let r = 0; r < PolygonBounds.length; r++) {
                    center = { lat: 0, lng: 0 };
                    let ring = [];
                    for (let p = 0; p < PolygonBounds[r].length; p++) {
                        let latlng = {
                            lat: PolygonBounds[r][p][1],
                            lng: PolygonBounds[r][p][0]
                        };
                        center.lat += latlng.lat;
                        center.lng += latlng.lng;
                        ring.push(latlng);
                    }
                    center.lat /= PolygonBounds[r].length;
                    center.lng /= PolygonBounds[r].length;
                    parkingSpacePaths.push(ring);
                }
                if (parkingSpacePaths.length == 1) {
                    parkingSpacePaths = parkingSpacePaths[0];
                }
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                mapsGeo = new google.maps.Polygon({
                    paths: parkingSpacePaths,
                    strokeColor: strokeColor.hexi,
                    strokeOpacity: 1.0,
                    strokeWeight: 1.0,
                    fillColor: color.hexi,
                    fillOpacity: 0.5,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        geoType: geoObject.Geo.type
                    }
                });
                mapsGeo.setMap(map);
                this.PolygonsLower.push(mapsGeo);
                if (clickEvent != null) {
                    google.maps.event.addListener(mapsGeo, 'click', function (event) {
                        clickEvent(type, id);
                    });
                }
                if (mouseOverEvent != null) {
                    google.maps.event.addListener(mapsGeo, 'mouseover', function (event) {
                        mouseOverEvent(type, id);
                    });
                }
                if (mouseOutEvent != null) {
                    google.maps.event.addListener(mapsGeo, 'mouseout', function (event) {
                        mouseOutEvent();
                    });
                }
                return;
            }
            case "MultiPolygon": {
                for (let g = 0; g < geoObject.Geo.coordinates.length; g++) {
                    let multiPolygonBounds = geoObject.Geo.coordinates[g];
                    let multiPolygonPaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < multiPolygonBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < multiPolygonBounds[r].length; p++) {
                            let latlng = {
                                lat: multiPolygonBounds[r][p][1],
                                lng: multiPolygonBounds[r][p][0]
                            };
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            ring.push(latlng);
                        }
                        center.lat /= multiPolygonBounds[r].length;
                        center.lng /= multiPolygonBounds[r].length;
                        multiPolygonPaths.push(ring);
                    }
                    if (multiPolygonPaths.length == 1) {
                        multiPolygonPaths = multiPolygonPaths[0];
                    }
                    geoObject.Center = center;
                    // Construct the polygon, including both paths.
                    mapsGeo = new google.maps.Polygon({
                        paths: multiPolygonPaths,
                        strokeColor: strokeColor.hexi,
                        strokeOpacity: 1.0,
                        strokeWeight: 1,
                        fillColor: color.hexi,
                        fillOpacity: 0.5,
                        zIndex: zIndex,
                        // position: center
                    });
                    Object.assign(mapsGeo, {
                        data: {
                            id: geoObject.Index,
                            geoType: geoObject.Geo.type
                        }
                    });
                    mapsGeo.setMap(map);
                    this.PolygonsLower.push(mapsGeo);
                    if (clickEvent != null) {
                        google.maps.event.addListener(mapsGeo, 'click', function (event) {
                            clickEvent(type, id);
                        });
                    }
                    if (mouseOverEvent != null) {
                        google.maps.event.addListener(mapsGeo, 'mouseover', function (event) {
                            mouseOverEvent(type, id);
                        });
                    }
                    if (mouseOutEvent != null) {
                        google.maps.event.addListener(mapsGeo, 'mouseout', function (event) {
                            mouseOutEvent();
                        });
                    }
                    return;
                }
            }
            case "Collection": /// ACCCServer incorrectly calls this Collection (http://gitserver/aci-software/ACCCServer/issues/28)
            case "GeometryCollection":
                let geometries = geoObject.Geo.geometries || geoObject.Geo.items; /// ACCCServer incorrectly calls this items  (http://gitserver/aci-software/ACCCServer/issues/28)
                for (let i = 0; i < geometries.length; i++) {
                    let newGeoObject = { Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center };
                    //let newGeoObject: GeoObject = { GeoId: geoObject.GeoId, Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center }
                    this.drawPolygonOnMap(map, newGeoObject, type, id, color, strokeColor, zIndex, clickEvent, mouseOverEvent, mouseOutEvent);
                    //this.showGeoObjectOnMap(map, area, geoType, newGeoObject, result, bounds, clickEvent)
                }
                return;
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    drawPolygonOnMapBorderOnly(map, geoObject, type, id, color, zIndex, clickEvent, mouseOverEvent, mouseOutEvent) {
        if (!geoObject || !geoObject.Geo || !geoObject.Geo.type)
            return;
        let mapsGeo;
        switch (geoObject.Geo.type) {
            case "Polygon": {
                let PolygonBounds = geoObject.Geo.coordinates;
                let parkingSpacePaths = [];
                let center = { lat: 0, lng: 0 };
                for (let r = 0; r < PolygonBounds.length; r++) {
                    center = { lat: 0, lng: 0 };
                    let ring = [];
                    for (let p = 0; p < PolygonBounds[r].length; p++) {
                        let latlng = {
                            lat: PolygonBounds[r][p][1],
                            lng: PolygonBounds[r][p][0]
                        };
                        center.lat += latlng.lat;
                        center.lng += latlng.lng;
                        ring.push(latlng);
                    }
                    center.lat /= PolygonBounds[r].length;
                    center.lng /= PolygonBounds[r].length;
                    parkingSpacePaths.push(ring);
                }
                if (parkingSpacePaths.length == 1) {
                    parkingSpacePaths = parkingSpacePaths[0];
                }
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                mapsGeo = new google.maps.Polygon({
                    paths: parkingSpacePaths,
                    strokeColor: color.hexi,
                    strokeOpacity: 1.0,
                    strokeWeight: 5,
                    fillColor: color.hexi,
                    fillOpacity: 0.0,
                    zIndex: zIndex,
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        geoType: geoObject.Geo.type
                    }
                });
                mapsGeo.setMap(map);
                this.Polygons.push(mapsGeo);
                if (clickEvent != null) {
                    google.maps.event.addListener(mapsGeo, 'click', function (event) {
                        clickEvent(type, id);
                    });
                }
                if (mouseOverEvent != null) {
                    google.maps.event.addListener(mapsGeo, 'mouseover', function (event) {
                        mouseOverEvent(type, id);
                    });
                }
                if (mouseOutEvent != null) {
                    google.maps.event.addListener(mapsGeo, 'mouseout', function (event) {
                        mouseOutEvent();
                    });
                }
                return;
            }
            case "MultiPolygon": {
                for (let g = 0; g < geoObject.Geo.coordinates.length; g++) {
                    let multiPolygonBounds = geoObject.Geo.coordinates[g];
                    let multiPolygonPaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < multiPolygonBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < multiPolygonBounds[r].length; p++) {
                            let latlng = {
                                lat: multiPolygonBounds[r][p][1],
                                lng: multiPolygonBounds[r][p][0]
                            };
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            ring.push(latlng);
                        }
                        center.lat /= multiPolygonBounds[r].length;
                        center.lng /= multiPolygonBounds[r].length;
                        multiPolygonPaths.push(ring);
                    }
                    if (multiPolygonPaths.length == 1) {
                        multiPolygonPaths = multiPolygonPaths[0];
                    }
                    geoObject.Center = center;
                    // Construct the polygon, including both paths.
                    mapsGeo = new google.maps.Polygon({
                        paths: multiPolygonPaths,
                        strokeColor: color.hexi,
                        strokeOpacity: 1.0,
                        strokeWeight: 5,
                        fillColor: color.hexi,
                        fillOpacity: 0.0,
                        zIndex: zIndex,
                    });
                    Object.assign(mapsGeo, {
                        data: {
                            id: geoObject.Index,
                            geoType: geoObject.Geo.type
                        }
                    });
                    mapsGeo.setMap(map);
                    this.Polygons.push(mapsGeo);
                    if (clickEvent != null) {
                        google.maps.event.addListener(mapsGeo, 'click', function (event) {
                            clickEvent(type, id);
                        });
                    }
                }
                return;
            }
            case "Collection": /// ACCCServer incorrectly calls this Collection (http://gitserver/aci-software/ACCCServer/issues/28)
            case "GeometryCollection":
                let geometries = geoObject.Geo.geometries || geoObject.Geo.items; /// ACCCServer incorrectly calls this items  (http://gitserver/aci-software/ACCCServer/issues/28)
                for (let i = 0; i < geometries.length; i++) {
                    //let newGeoObject: GeoObject = { GeoId: geoObject.GeoId, Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center }
                    let newGeoObject = { Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center };
                    this.drawPolygonOnMapBorderOnly(map, newGeoObject, type, id, color, zIndex, clickEvent, mouseOverEvent, mouseOutEvent);
                    //this.showGeoObjectOnMap(map, area, geoType, newGeoObject, result, bounds, clickEvent)
                }
                return;
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    drawNumberMarker(map, number, latlng) {
        if (!latlng)
            return;
        var marker = new google.maps.Marker({
            position: latlng,
            label: { color: '#ffffff', fontWeight: 'bold', fontSize: '20px', text: number },
            optimized: false,
            visible: true
        });
        marker.setMap(map);
        this.NumberMarkers.push(marker);
    }
    removePolygons(map) {
        let temp = [];
        for (let polygon of this.Polygons) {
            if (polygon.getMap() === map)
                polygon.setMap(null);
            else
                temp.push(polygon);
        }
        this.Polygons = temp;
    }
    async removePolygonsAsync() {
        await asyncMapArray(this.Polygons, 10, (geoInstance) => {
            geoInstance.setMap(null);
        });
        this.Polygons = [];
    }
    removePolygonsLowerlayer(map) {
        let temp = [];
        for (let polygonLower of this.PolygonsLower) {
            if (polygonLower.getMap() === map)
                polygonLower.setMap(null);
            else
                temp.push(polygonLower);
        }
        this.PolygonsLower = temp;
    }
    async removePolygonsLowerlayerAsync() {
        await asyncMapArray(this.PolygonsLower, 10, (geoInstance) => {
            geoInstance.setMap(null);
        });
        this.PolygonsLower = [];
    }
    removeWaySegments(map) {
        let temp = [];
        for (let waySegment of this.waySegments) {
            if (waySegment.getMap() === map)
                waySegment.setMap(null);
            else
                temp.push(waySegment);
        }
        this.waySegments = temp;
    }
    async removeWaySegmentsAsync() {
        await asyncMapArray(this.waySegments, 10, (geoInstance) => {
            geoInstance.setMap(null);
        });
        this.waySegments = [];
    }
    removeWaySegmentsThick(map) {
        let temp = [];
        for (let waySegmentThick of this.waySegmentsThick) {
            if (waySegmentThick.getMap() === map)
                waySegmentThick.setMap(null);
            else
                temp.push(waySegmentThick);
        }
        this.waySegmentsThick = temp;
    }
    async removeWaySegmentsThickAsync() {
        await asyncMapArray(this.waySegmentsThick, 10, (geoInstance) => {
            geoInstance.setMap(null);
        });
        this.waySegmentsThick = [];
    }
    removeNumberMarkers(map) {
        let temp = [];
        for (let NumberImage of this.NumberMarkers) {
            if (NumberImage.getMap() === map)
                NumberImage.setMap(null);
            else
                temp.push(NumberImage);
        }
        this.NumberMarkers = temp;
    }
    setVisiblityPolygons(map, visible) {
        for (let polygon of this.Polygons) {
            if (polygon.getMap() === map)
                polygon.setVisible(visible);
        }
    }
    async setVisiblityPolygonsAsync(visible) {
        await asyncMapArray(this.Polygons, 10, (geoInstance) => {
            geoInstance.setVisible(visible);
        });
    }
    setFillOpacityPolygonsLowerLayer(map, Opacity) {
        for (let polygonLower of this.PolygonsLower) {
            if (polygonLower.getMap() === map)
                polygonLower.setOptions({ fillOpacity: Opacity });
        }
    }
    setVisiblityPolygonsLowerLayer(map, visible) {
        for (let polygonLower of this.PolygonsLower) {
            if (polygonLower.getMap() === map)
                polygonLower.setVisible(visible);
        }
    }
    async setVisiblityPolygonsLowerLayerAsync(visible) {
        await asyncMapArray(this.PolygonsLower, 10, (geoInstance) => {
            geoInstance.setVisible(visible);
        });
    }
    setVisiblityWaySegment(map, id, visible) {
        const waysegments = this.waySegments.filter(r => r.data.id === id);
        for (const w of waysegments) {
            if (w && w.getMap() == map)
                w.setVisible(visible);
        }
    }
    setVisiblityWaySegments(map, visible) {
        for (let waySegment of this.waySegments) {
            if (waySegment.getMap() == map)
                waySegment.setVisible(visible);
        }
    }
    async setVisiblityWaySegmentsAsync(visible) {
        await asyncMapArray(this.waySegments, 10, (geoInstance) => {
            geoInstance.setVisible(visible);
        });
    }
    setVisiblityWaySegmentsThick(map, visible) {
        for (let waySegmentThick of this.waySegmentsThick) {
            if (waySegmentThick.getMap() === map)
                waySegmentThick.setVisible(visible);
        }
    }
    async setVisiblityWaySegmentsThickAsync(visible) {
        await asyncMapArray(this.waySegmentsThick, 10, (geoInstance) => {
            geoInstance.setVisible(visible);
        });
    }
    fitBoundsPolygons(map) {
        if (map === undefined || !this.Polygons.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < this.Polygons.length; i++) {
            // @ts-ignore
            this.Polygons[i].getPath().forEach(function (element, index) { bounds.extend(element); });
        }
        map.fitBounds(bounds);
    }
    async fitBoundsPolygonsAsync(map) {
        if (map === undefined || !this.Polygons.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        await asyncMapArray(this.Polygons, 10, (geoInstance) => {
            if (geoInstance instanceof google.maps.Polyline || geoInstance instanceof google.maps.Polygon) {
                geoInstance.getPath().forEach(function (element, index) { bounds.extend(element); });
            }
            else {
                bounds.extend(getCenterAny(geoInstance));
            }
        });
        map.fitBounds(bounds);
    }
    fitBoundsPolygonsLowerLayer(map) {
        if (map === undefined || !this.PolygonsLower.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < this.PolygonsLower.length; i++) {
            // @ts-ignore
            this.PolygonsLower[i].getPath().forEach(function (element, index) { bounds.extend(element); });
        }
        map.fitBounds(bounds);
    }
    async fitBoundsPolygonsLowerLayerAsync(map) {
        if (map === undefined || !this.PolygonsLower.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        await asyncMapArray(this.PolygonsLower, 10, (geoInstance) => {
            if (geoInstance instanceof google.maps.Polyline || geoInstance instanceof google.maps.Polygon) {
                geoInstance.getPath().forEach(function (element) { bounds.extend(element); });
            }
            else {
                bounds.extend(getCenterAny(geoInstance));
            }
        });
        map.fitBounds(bounds);
    }
    fitBoundsWaySegments(map) {
        if (map === undefined || !this.waySegments.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        for (const waySegment of this.waySegments) {
            if (waySegment.getVisible() && waySegment.getMap() == map) {
                // @ts-ignore
                waySegment.getPath().forEach(function (item, index) {
                    bounds.extend(new google.maps.LatLng(item.lat(), item.lng()));
                });
            }
        }
        map.fitBounds(bounds);
    }
    async fitBoundsWaySegmentsAsync(map) {
        if (map === undefined || !this.waySegments.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        await asyncMapArray(this.waySegments, 10, (geoInstance) => {
            if (geoInstance instanceof google.maps.Polyline || geoInstance instanceof google.maps.Polygon) {
                geoInstance.getPath().forEach(function (element) { bounds.extend(element); });
            }
            else {
                bounds.extend(getCenterAny(geoInstance));
            }
        });
        map.fitBounds(bounds);
    }
    fitBoundsWaySegmentsThick(map) {
        if (map === undefined || !this.waySegmentsThick.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        for (const i in this.waySegmentsThick) {
            // @ts-ignore
            this.waySegmentsThick[i].getPath().forEach(function (item, index) {
                bounds.extend(new google.maps.LatLng(item.lat(), item.lng()));
            });
        }
        map.fitBounds(bounds);
    }
    async fitBoundsWaySegmentsThickAsync(map) {
        if (map === undefined || !this.waySegmentsThick.length)
            return;
        var bounds = new google.maps.LatLngBounds();
        await asyncMapArray(this.waySegmentsThick, 10, (geoInstance) => {
            if (geoInstance instanceof google.maps.Polyline || geoInstance instanceof google.maps.Polygon) {
                geoInstance.getPath().forEach(function (item) { bounds.extend(new google.maps.LatLng(item.lat(), item.lng())); });
            }
            else {
                bounds.extend(getCenterAny(geoInstance));
            }
        });
        map.fitBounds(bounds);
    }
    async Clear() {
        this.removePolygonsAsync();
        this.removePolygonsLowerlayerAsync();
        this.removeWaySegmentsAsync();
        this.removeWaySegmentsThickAsync();
    }
}
