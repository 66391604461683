import { AColorHSV } from "../../classes/AColorHSV.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ADragService } from "../../services/ADragService.js";
import { AMapOverlayService, MAP_POSITION } from "../../services/AMapOverlayService.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { createMap } from "../../utils/maps.js";
import { AFormatDate, AInputDate, metersToKilometerText } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.map = createMap('map', {
            zoom: 24
        });
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        // Move legend to map
        AEngine.get(AMapOverlayService).add(this.map, this.map.getDiv(), $('#map_legend'), MAP_POSITION.BOTTOM_LEFT);
        this.devices = [];
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
        this.routeMapHelperService.Clear();
    }
    async init() {
        this.map.fit();
        this.translations = await Loading.waitForPromises(Translate.get([
            'Delete Planned Route',
            'Route Deleted',
            'Planning Deleted',
            'Delete planning',
            'Delete route from planning',
            'Route will be deleted from',
            'Planning'
        ]));
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        AEngine.get(ADragService).createDraggableGeneric();
        $('#RefreshButton').prop('disabled', true);
        this.showInfoScreen('disabled');
        if (await Loading.waitForPromises(routePlannerService.pollRouteService())) {
            this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap());
            this.refresh();
            $('#RefreshButton').prop('disabled', false);
        }
        //this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap())
        //this.refresh()
    }
    async refresh() {
        const data = FilterManager.saveExplicit();
        const from = new Date(data.FromDate);
        const to = new Date(data.ToDate);
        this.showInfoScreen("splash");
        this.routeMapHelperService.removeWaySegments(this.map);
        this.map.resetBounds();
        this.drivenRoutes = await Loading.waitForPromises(routePlannerService.fetchDrivenRoutes(from, to, data.DeviceName));
        if (this.drivenRoutes != null) {
            this.createRoutesDoneRows();
        }
    }
    showInfoScreen(screen) {
        $('#info_overview_disabled').hide();
        $('#info_overview_splash').hide();
        $('#info_overview_driven').hide();
        $('#info_overview_planned').hide();
        if (screen === "disabled") {
            $('#info_overview_disabled').show();
        }
        else if (screen === "splash") {
            $('#info_overview_splash').show();
        }
        else if (screen === "driven") {
            $('#info_overview_driven').show();
        }
        else if (screen === "planned") {
            $('#info_overview_planned').show();
        }
    }
    createRoutesDoneRows() {
        $('#routes_driven_list').children().remove();
        for (const index in this.drivenRoutes) {
            const dRoute = this.drivenRoutes[index];
            var $row = $(/*html*/ `
        <tr id="${index}" class='tableRow'>
        <td>
          <div class='columns' style="margin: 2px;">
            <h7 class='col-5'>${AInputDate(new Date(dRoute.From))}</h7>
            <h7 style="text-align: right;" class='col-7'>${dRoute.DeviceName}</h7>
          <h5 class='col-12'>${dRoute.RouteName}</h5>
        </div>
      </td>  
      </tr>
      `.replace(/\s\s+/g, ' '));
            $('#routes_driven_list').append($row);
        }
        const th = this;
        $('#routes_driven_list').on('click', 'tr', async function () {
            $("table > tbody > tr").each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            await Loading.waitForPromises(th.onDrivenRowClicked(this.id));
        });
    }
    async onDrivenRowClicked(index) {
        const dRoute = this.drivenRoutes[index];
        this.routeMapHelperService.removeWaySegments(this.map);
        for (const waySegmentId of dRoute.WaySegmentsDone) {
            if (this.geoMap.WaySegments[waySegmentId]) {
                this.routeMapHelperService.drawWaySegment_new(this.map, this.geoMap.WaySegments[waySegmentId], this.waySegmentColors.green, 3.0, 7.0);
            }
        }
        for (const waySegmentId of dRoute.WaySegmentsToDo) {
            if (this.geoMap.WaySegments[waySegmentId]) {
                this.routeMapHelperService.drawWaySegment_new(this.map, this.geoMap.WaySegments[waySegmentId], this.waySegmentColors.red, 3.0, 7.0);
            }
        }
        for (const waySegmentId of dRoute.WaySegmentsOnRouteDone) {
            if (this.geoMap.WaySegments[waySegmentId]) {
                this.routeMapHelperService.drawWaySegment_new(this.map, this.geoMap.WaySegments[waySegmentId], this.waySegmentColors.lime, 3.0, 7.0);
            }
        }
        for (const waySegmentId of dRoute.WaySegmentsOnRoute) {
            if (this.geoMap.WaySegments[waySegmentId]) {
                this.routeMapHelperService.drawWaySegment_new(this.map, this.geoMap.WaySegments[waySegmentId], this.waySegmentColors.orange, 3.0, 7.0);
            }
        }
        for (const waySegmentId of dRoute.WaySegmentsOther) {
            if (this.geoMap.WaySegments[waySegmentId]) {
                this.routeMapHelperService.drawWaySegment_new(this.map, this.geoMap.WaySegments[waySegmentId], this.waySegmentColors.blue, 3.0, 7.0);
            }
        }
        this.routeMapHelperService.fitBoundsWaySegments(this.map);
        this.fillInfoScreenDriven(index);
    }
    fillInfoScreenDriven(id) {
        this.showInfoScreen("driven");
        const dRoute = this.drivenRoutes[id];
        $('#info_driven_name').text(dRoute.RouteName);
        $('#info_driven_device').text(dRoute.DeviceName);
        let $users = "-";
        if (dRoute.Users.length > 0) {
            $users = dRoute.Users[0];
            for (let u = 1; u < dRoute.Users.length; u++) {
                $users += ", " + dRoute.Users[u];
            }
        }
        $('#info_driven_user').text($users);
        let totalDistance = dRoute.DistDone + dRoute.DistToDo;
        $('#info_driven_distanceroute').text(metersToKilometerText(totalDistance));
        $('#info_driven_distancedriven').text(metersToKilometerText(dRoute.DistDone));
        let perc = Math.round((dRoute.DistDone / totalDistance) * 100);
        $('#info_driven_percentage').text(perc + "%");
        $('#info_driven_from').text(AFormatDate(new Date(dRoute.From)));
        $('#info_driven_to').text(AFormatDate(new Date(dRoute.To)));
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .fixTableHead {
        overflow-y: auto;
      }
      .fixTableHead thead th {
        position: sticky;
        background: #F9F9F9;
        top: 0;
      }
      .tableRow {
        background: #ffffff;
      }
      .tableRow:hover {
        background: #F9F9F9;
      }
      .tableRow.selected {
        background: #ECECEC;
      }

      .tableRowInActive {
        background: #F8F8F8;
        color: #BBBBBB;
      }
      .tableRowInActive.selected {
        background: #EBEBEB;
        color: #BBBBBB;
      }
      .tableRowInActive:hover {
        background: #F1F1F1;
      }

      .legend-label.legend-label-sub {
        font-size: 11px;
        line-height: 1.5em;
      }

      .accordion-wrapper > a {
        padding: 15px 8px
      }      

      .accordion-wrapper .tableWrapper {
        max-height: 400px;
        overflow-y: auto;
      }

      #info_overview .display-once {
        background: transparent;
      }

      #info_overview #info_overview_splash {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      #info_overview #info_overview_disabled {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      
      #info_overview {
        height: 250px;
      }

      .info-block {
        display: flex;
        text-align: center;
        height: calc(100% / 3.0);
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
      
      .info-block h6,
      .info-block span {
        margin-bottom: 0;
      }

      .mapWrapper {
        position: relative;
        height: calc(100% - 250px);
        width: 100%;
      }

      .aci-map + .legend {
        display: none;
      }

    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div class="flex-child fh">
      <div class="flex-content">
        <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">

          <div class="drag-section part-one" style="width: 30%; min-width: 180px;">

            <div class="fixTableHead full-height">
              <table style="table-layout: fixed; width: 100%;" class="table">
                <thead>
                <tr>
                  <th><b>Routes Driven</b></th>
                </tr>
                </thead>
                <tbody id="routes_driven_list">
                </tbody>
              </table>
            </div>
            
          </div>
          
          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two" style="width: 70%; min-width: 180px;">
            <div id='map_wrapper' class="mapWrapper">
              <div id="map" class="aci-map"></div>
              <div id="map_legend" class="legend legend-opaque">
                <div class="legend-label legend-label-sub">Route Driven</div>

                <div class="legend-item">
                  <div class="route-preview" style="background-color: #00FF00; border-color: #00cf00"></div>
                  <span>With Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #F4FF00; border-color: #d7e000"></div>
                  <span>Without Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #7DCCDF; border-color: #67a6b5"></div>
                  <span>Off Route</span>
                </div>

                <div class="legend-label legend-label-sub mt-1">Route ToDo</div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #fc0303; border-color: #a30000"></div>
                  <span>With Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #FF8F0F; border-color: #e08722"></div>
                  <span>Without Parking</span>
                </div>
              </div>
            </div>

            <div id="info_overview">
              <div id="info_overview_disabled">
                <h5 class='col-12' style="text-align: center; color: lightgray;">Service Not Running</h5>
              </div>

              <div id="info_overview_splash">
                <div template="${ATemplates.RouteInformation}"></div>
                <!--<h1 class='col-12' style="text-align: center; color: lightgray; margin-top: 70px;">Route Information</h1>-->
              </div>
              
              <div id="info_overview_driven" class="columns fh" style="display: none;">

                <div class="col-4 info-block">
                  <h6>Route Name</h6>
                  <span id="info_driven_name">ROUTENAME</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Device</h6>
                  <span id="info_driven_device">DEVICE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>User</h6>
                  <span id="info_driven_user">USER</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Route distance</h6>
                  <span id="info_driven_distanceroute">DISTANCE ROUTE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Route driven</h6>
                  <span id="info_driven_distancedriven">DISTANCE DRIVEN</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Percentage done</h6>
                  <span id="info_driven_percentage">PERCENTAGE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>From</h6>
                  <span id="info_driven_from">FROM DATE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>To</h6>
                  <span id="info_driven_to">TO DATE</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  `);
}
