import { AError } from "../../classes/AError.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertMillisecondsToHM, AFormat2Dec, AFormatDate, AInputDate, AInputDateTime, AInputTime, createSelectScanDeviceListHtmlAll, metersToKilometerText, secondsToDurationTextHHMM } from "../../utils/tools.js";
import { createMap } from "../../utils/maps.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { AColorHSV } from "../../classes/AColorHSV.js";
import { AMapOverlayService, MAP_POSITION } from "../../services/AMapOverlayService.js";
import { initToolTips } from "../../utils/tooltip.js";
export class APage {
    constructor() {
        this.quickSelectBtns = {
            'Today': {
                onClick: ($filters) => {
                    $filters.find('#FromDate').val(AInputDate(new Date()));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(new Date()));
                    $filters.find('#ToTime').val('23:59');
                    FilterManager.selectShortcut('.quick-0');
                }
            },
            'Tomorrow': {
                onClick: ($filters) => {
                    const { tomorrowDate } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(tomorrowDate));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(tomorrowDate));
                    $filters.find('#ToTime').val('23:59');
                    FilterManager.selectShortcut('.quick-1');
                }
            },
            'Yesterday': {
                onClick: ($filters) => {
                    const { yesterdayDate } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(yesterdayDate));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(yesterdayDate));
                    $filters.find('#ToTime').val('23:59');
                    FilterManager.selectShortcut('.quick-2');
                }
            },
            'This Week': {
                onClick: ($filters) => {
                    const { startOfWeek, endOfWeek } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(startOfWeek));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(endOfWeek));
                    $filters.find('#ToTime').val('23:59');
                    FilterManager.selectShortcut('.quick-3');
                }
            },
            'Next Week': {
                onClick: ($filters) => {
                    const { startOfNextWeek, endOfNextWeek } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(startOfNextWeek));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(endOfNextWeek));
                    $filters.find('#ToTime').val('23:59');
                    FilterManager.selectShortcut('.quick-4');
                }
            }
        };
        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.routes_new = [];
        this.requests_new = [];
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        $('#ExportButton').on('click', _ => this.exportCSV());
    }
    async init() {
        FilterManager.selectShortcut('.quick-0');
        $('#FromDate').val(AInputDate(new Date()));
        $('#FromTime').val('06:00');
        $('#ToDate').val(AInputDate(new Date()));
        $('#ToTime').val('23:59');
        this.plannedRoutes = [];
        this.drivenRoutes = [];
        this.routes_new = [];
        this.requests_new = [];
        this.planning_map = $('#planning_map'); // Create JQuery collection with id='map'
        this.planning_map = createMap('planning_map', {
            zoom: 24
        });
        this.planning_map.fit();
        AEngine.get(AMapOverlayService).add(this.planning_map, this.planning_map.getDiv(), $("#planning_map_legend"), MAP_POSITION.BOTTOM_LEFT);
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
        this.routeMapHelperService.Clear();
        this.translations = await Loading.waitForPromises(Translate.get([
            'To',
            'From',
            'Occupancy',
            'VisitorRate',
            'Compliancy',
            'CompliancyVisitors',
            'EnforcementIntensity',
            'Device',
            'Route',
            'Date',
            'Copy to',
            'New',
            'Save',
            'Send',
            'Back',
            'Next',
            'Edit Planning',
            'Copy Planning',
            'New Planning',
            'Add Break',
            'Edit Break',
            'Delete Break',
            'Resend Planning',
            'Delete Planning',
            'To Car',
            'Sort RouteAreas on',
            'Last Enforcement',
            'Routes Planned',
            'Date Start',
            'Date Finish',
            'Start',
            'Finish',
            'Name',
            'Estimate length',
            'Estimate duration',
            'Route Name',
            'Duration',
            'Now',
            'Warning: Overlapping Planning',
            'New Planning Overlaps With',
            'Continue',
            'Not connected',
            'Car is currenly not connected, route will be send when connected',
            'Received',
            'Route is received',
            'Create',
            'Visitor Rate',
            'Parking Pressure',
            'Enforcement Intensity',
            'Filter Regimes',
            'Regime'
        ]));
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            aqua: new AColorHSV(180, 63, 96),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        this.subscribeToRouteServiceMsgs();
        $('#btn_add_planning_routes').prop('disabled', true);
        $('#btn_add_planning_routearea').prop('disabled', true);
        $('#btn_add_break').prop('disabled', true);
        $('#btn_reset_view').prop('disabled', true);
        $('#btn_add_planning_routes').on('click', _ => this.alert_newPlanningRoutes());
        $('#btn_add_planning_routearea').on('click', _ => this.alert_newPlanningRouteArea());
        $('#btn_add_break').on('click', _ => this.alert_newBreak());
        $('#btn_reset_view').on('click', _ => this.resetView());
        //this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap())
        //this.geoMap = await Loading.waitForPromises(ARoutePlannerStruct.fetchGeoMap())
        $('#timeline').hide();
        $('#timeline_disabled').show();
        if (await Loading.waitForPromises(routePlannerService.pollRouteService())) {
            this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap());
            $('#timeline').show();
            $('#timeline_disabled').hide();
            this.refresh();
        }
        else {
            this.resetInfo();
        }
    }
    subscribeToRouteServiceMsgs() {
        Events.on(`PlanRoute_ResendRouteToCarByShareId_Response`, response => {
            if (response.State == "NotOnline") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: this.translations['Not connected'],
                    content: this.translations['Car is currenly not connected, route will be send when connected']
                });
            }
            else if (response.State == "Ok") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: this.translations['Received'],
                    content: this.translations['Route is received']
                });
            }
            else {
                Alerts.show({
                    title: ALERT_TITLES.Error,
                    content: response.StateText,
                    type: ALERTS.Form,
                });
            }
        });
    }
    async refresh() {
        let $page = this;
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        const filter_data = FilterManager.saveExplicit();
        const from = new Date(filter_data.FromDate);
        const to = new Date(filter_data.ToDate);
        const Device = $('#DeviceName').val();
        this.drivenRoutes = await Loading.waitForPromises(routePlannerService.fetchDrivenRoutes(from, to, filter_data.DeviceName));
        this.plannedRoutes = await Loading.waitForPromises(routePlannerService.fetchRoutePlanningDevices(from, to, Device));
        await Loading.waitForPromises([
            //this.getPlannedRoutes(),
            Translate.get(this.months).then(data => this.monthsTranslated = data)
        ]);
        // TODO: Code Review /w Ivan
        await Loading.waitForPromises([
            routePlannerService.fetchRoutes(),
            routePlannerService.fetchRequests(),
        ]).then(([routes_new, requests_new]) => {
            this.routes_new = routes_new;
            this.requests_new = requests_new;
        });
        this.mergeDrivenWithPlanned();
        function verticalTimeLines() {
            let result = [{
                    color: "#FF0000",
                    width: 2,
                    value: new Date().getTime(),
                    label: {
                        text: $page.translations['Now'],
                        y: -5,
                        x: -1,
                        align: 'center',
                        rotation: 0
                    },
                    zIndex: 10
                }];
            let date = new Date(filters.FromDate);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            //const $to = new Date(filters.ToDate)
            const timediff = to.getTime() - from.getTime();
            let increaseValue = (24 * 60 * 60 * 1000); // one day, default
            if (timediff <= (24 * 60 * 60 * 1000)) { // <= 24h
                increaseValue = (15 * 60 * 1000); // 15 min
            }
            else if (timediff <= (6 * 24 * 60 * 60 * 1000)) { // <= 6 days
                increaseValue = (60 * 60 * 1000); // 1 hour
            }
            do {
                date.setTime(date.getTime() + increaseValue);
                result.push({
                    color: "#f0f0f0",
                    width: 1,
                    value: date.getTime(),
                    zIndex: 0
                });
            } while (date < to);
            return result;
        }
        function seriesPointWidth() {
            switch (Object.keys($page.plannedRoutes).length) {
                case 1: return 100;
                case 2: return 85;
                case 3: return 75;
                case 4: return 60;
                case 5: return 50;
                case 6: return 45;
                case 7: return 40;
                case 8: return 35;
                case 9: return 30;
                case 10: return 25;
                default: return 20;
            }
        }
        const data = this.transformPlannedRoutes();
        let chart_title = AFormat2Dec(from.getDate()) + " " + $page.monthsTranslated[$page.months[from.getMonth()]];
        if (AInputDate(from) != AInputDate(to))
            chart_title += " - " + AFormat2Dec(to.getDate()) + " " + $page.monthsTranslated[$page.months[to.getMonth()]];
        try {
            function selectPointsByDrag(e) {
                if (this.yAxis && this.yAxis[0].categories.length == 0)
                    return false;
                const moreThanOne = this.yAxis[0].categories.length > 1;
                if (moreThanOne && (!e.yAxis || e.yAxis.length == 0))
                    return false;
                let catSelected = "";
                this.series.forEach(series => {
                    series.points.forEach(point => {
                        point.select(false, true);
                        if (point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max && (moreThanOne ? point.y >= e.yAxis[0].min && point.y <= e.yAxis[0].max : true)) {
                            if (moreThanOne && catSelected === "")
                                catSelected = point.yCategory;
                            else if (moreThanOne && catSelected != point.yCategory)
                                return false;
                            point.groupId = 'selected';
                            point.select(true, true);
                        }
                    });
                });
                $page.onPlanningSelected(this.getSelectedPoints());
                return false; // Don't zoom
            }
            this.chart = Highcharts.chart({
                chart: {
                    renderTo: 'timeline',
                    type: 'xrange',
                    zooming: { type: 'xy', resetButton: { position: { align: 'left', verticalAlign: 'top' } } },
                    events: {
                        redraw() {
                            // @ts-ignore // TODO: FIX HIGHCHARTS
                            if (this.resetZoomButton)
                                this.resetZoomButton.hide();
                            $('#btn_reset_view').prop('disabled', false);
                            $page.redrawEvent();
                        },
                        click: function () {
                            $page.resetView();
                        },
                        selection: selectPointsByDrag
                    }
                },
                title: {
                    text: this.translations['Routes Planned'],
                    style: {
                        color: '#4d4d4d',
                        fontWeight: 'bold',
                        fontSize: "15"
                    }
                },
                subtitle: {
                    text: chart_title,
                    style: {
                        fontSize: "25"
                    }
                },
                xAxis: {
                    type: 'datetime',
                    min: new Date(filters.FromDate).getTime(),
                    max: new Date(filters.ToDate).getTime(),
                    tickInterval: 3600 * 500,
                    plotLines: verticalTimeLines()
                },
                yAxis: {
                    title: { text: '' },
                    categories: Object.keys(this.plannedRoutes),
                    reversed: true
                },
                plotOptions: {
                    series: {
                        allowPointSelect: true,
                        dragDrop: {
                            draggableX: true,
                            draggableX1: false,
                            draggableX2: false,
                            draggableY: false,
                            dragMinX: Math.max(new Date(filters.FromDate).getTime(), new Date().getTime()),
                            //dragPrecisionX: (1000 * 60 * 15),
                            liveRedraw: false,
                            groupBy: 'selected'
                        },
                        events: {
                            click: function (e) {
                                // Deselect points
                                $page.chart.series.forEach(series => {
                                    series.points.forEach(point => {
                                        point.select(false, true);
                                    });
                                });
                                this.select(true);
                                $page.onPlanningSelected([e.point]);
                            }
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        // @ts-ignore
                        const { x, x2, y, routeId, duration, device, routename } = this.point;
                        const from = AFormatDate(new Date(x));
                        const to = AFormatDate(new Date(x2));
                        return (`
              <b>${$page.translations['Route Name']}</b>: ${routename}<br>
              <b>${$page.translations['Device']}</b>: ${device}<br>
              <b>${$page.translations['From']}</b> ${from}<br>
              <b>${$page.translations['To']}</b> ${to}
              <b>${$page.translations['Duration']}</b>: ${duration}<br>
            `);
                    }
                },
                series: [
                    {
                        type: 'xrange',
                        showInLegend: false,
                        pointWidth: seriesPointWidth(),
                        data: data.map((item) => { return Object.assign({}, item, {}); }),
                        dataLabels: { enabled: false },
                        states: {
                            hover: { borderColor: '#8FB1D0', lineWidth: 5 },
                            select: { borderColor: '#5F768B', lineWidth: 5 }
                        },
                        point: {
                            events: {
                                drop: function (e) {
                                    console.log(e.newPoints);
                                    const options = $page.chart.getOptions();
                                    for (const index in e.newPoints) {
                                        const point = e.newPoints[index];
                                        const newValues = point.newValues;
                                        if (newValues && newValues.x && newValues.x2) {
                                            // 900000 = ~ 15 min from now 
                                            if ((newValues.x - 900000) > options.plotOptions.series.dragDrop.dragMinX) {
                                                $page.onPlanningDrop(point.point, new Date(newValues.x), new Date(newValues.x2));
                                            }
                                            else
                                                return false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                exporting: { allowHTML: true },
                legend: {},
            });
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
        }
        let routeservicerunning = ($page.geoMap != undefined && $page.geoMap.WaySegments != undefined);
        if (routeservicerunning) {
            $('#btn_add_planning_routes').prop('disabled', true);
            for (const route of Object.values(this.routes_new)) {
                if (route.Active) {
                    $('#btn_add_planning_routes').prop('disabled', false);
                    break;
                }
            }
            $('#btn_add_planning_routearea').prop('disabled', false);
            $('#btn_add_break').prop('disabled', false);
        }
        else {
            $('#btn_add_planning_routes').prop('disabled', true);
            $('#btn_add_planning_routearea').prop('disabled', true);
            $('#btn_add_break').prop('disabled', true);
        }
        if (this.plannedRoutes && Object.keys(this.plannedRoutes).length) {
            $('#ExportButton').prop('disabled', false);
        }
        else {
            $('#ExportButton').prop('disabled', true);
        }
        this.setSeriesColors();
        this.resetInfo();
        this.redrawEvent();
    }
    resetView() {
        // Highcharts Version 11 doesn't have chart.zoom() method
        // this.chart.zoom()
        this.chart.xAxis[0].setExtremes(undefined, undefined);
        //this.redrawEvent()
        $('#btn_reset_view').prop('disabled', true);
    }
    redrawEvent() {
        // Deselect all in timeline
        this.chart.series.forEach(s => {
            s.data.forEach((point) => { point.select(false); });
        });
        this.routeMapHelperService.removeWaySegments(this.planning_map);
        this.resetInfo();
        // var zoom_changed_listener = this.planning_map.addListener("zoom_changed", () => {
        //   google.maps.event.removeListener(zoom_changed_listener);
        //   $('#btn_reset_view').prop('disabled', false)
        // });
        // var dragend_listener = this.planning_map.addListener("dragend", () => {
        //   google.maps.event.removeListener(dragend_listener);
        //   $('#btn_reset_view').prop('disabled', false)
        // });
        let $txt_device = $('#info_planning_device');
        let $txt_routename = $('#info_planning_name');
        let $txt_date = $('#info_planning_date');
        let $txt_from = $('#info_planning_from');
        let $txt_to = $('#info_planning_to');
        let $txt_distance = $('#info_planning_distance');
        let devices = [];
        let minTime, maxTime;
        let totalDistance = 0;
        if (!this.plannedRoutes)
            return;
        for (const scanDevice of Object.keys(this.plannedRoutes)) {
            let device = this.plannedRoutes[scanDevice];
            devices.push(device.Device);
            for (const route_planned of device.Routes) {
                const $route = this.routes_new.find(r => r.RouteId === route_planned.RouteId && r.CarNumber === route_planned.CarNumber);
                const $request = this.requests_new.find(r => r.RouteId === route_planned.RouteId);
                if (!minTime || route_planned.RouteFromTime < minTime)
                    minTime = route_planned.RouteFromTime;
                if (!maxTime || route_planned.RouteToTime > maxTime)
                    maxTime = route_planned.RouteToTime;
                if (!$request || (!$route && !$request) || !route_planned)
                    continue;
                if ($route) { // route exist
                    totalDistance += $route.Length;
                    this.drawRouteOnMap(this.planning_map, $route);
                }
                else { // only request availb
                    totalDistance += this.getParkingDistanceFromWaySegmentIds($request.ParkingStreetIds);
                    this.drawRequestOnMap(this.planning_map, $request);
                }
            }
        }
        let str_devices = devices.join(',');
        if (str_devices.length > 25)
            $txt_device.text("Multiple Devices");
        else
            $txt_device.text(str_devices);
        $txt_distance.text(metersToKilometerText(totalDistance));
        $txt_routename.text("Multiple Routes");
        $txt_date.text(AInputDate(new Date(minTime)));
        $txt_from.text(AInputTime(new Date(minTime)));
        $txt_to.text(AInputTime(new Date(maxTime)));
    }
    mergeDrivenWithPlanned() {
        if (this.plannedRoutes || !this.drivenRoutes || Object.keys(this.plannedRoutes).length === 0 || Object.keys(this.drivenRoutes).length === 0) {
            return;
        }
        for (const scanDevice of Object.keys(this.plannedRoutes)) {
            const pl = this.plannedRoutes[scanDevice];
            for (const i of Object.keys(this.drivenRoutes)) {
                const dRoute = this.drivenRoutes[i];
                if (pl.Device === dRoute.DeviceName) {
                    for (const pr of pl.Routes) {
                        if ((pr.RouteFromTime > dRoute.From && pr.RouteFromTime < dRoute.To) ||
                            (pr.RouteToTime > dRoute.From && pr.RouteToTime < dRoute.To) ||
                            (dRoute.From > pr.RouteFromTime && dRoute.From < pr.RouteToTime) ||
                            (dRoute.To > pr.RouteFromTime && dRoute.To < pr.RouteToTime)) {
                            let totalDistance = dRoute.DistDone + dRoute.DistToDo;
                            pr.PercentageDone = Math.round((dRoute.DistDone / totalDistance));
                        }
                    }
                }
            }
        }
    }
    setSeriesColors() {
        let series = this.chart.series;
        const { plannedRoutes, waySegmentColors } = this;
        for (const s in series) {
            let data = this.chart.series[s].data;
            for (const key in data) {
                const obj = data[key];
                if (obj.options.x && obj.options.x > new Date().getTime()) {
                    obj.graphic?.attr({
                        cursor: 'move'
                    });
                    obj.options.dragDrop = {
                        draggableX: true
                    };
                }
                else {
                    obj.options.dragDrop = {
                        draggableX: false
                    };
                }
                if (obj['routeId'] == "1") { // AKA break
                    obj.color = waySegmentColors.lime.hexi;
                }
                else { // Other
                    const $dr = plannedRoutes[obj['device']].Routes[obj['j']];
                    if ($dr.PercentageDone && $dr.PercentageDone > 0) {
                        obj.color = interpolateService.colorHSV(waySegmentColors.red, waySegmentColors.green, $dr.PercentageDone).hexi;
                    }
                    else if (new Date($dr.RouteToTime) < new Date()) { // past
                        obj.color = waySegmentColors.red.hexi;
                    }
                    else if (new Date($dr.RouteFromTime) < new Date() && new Date($dr.RouteToTime) > new Date()) {
                        obj.color = waySegmentColors.aqua.hexi;
                    }
                    else {
                        obj.color = waySegmentColors.blue.hexi;
                    }
                }
            }
        }
    }
    resetInfo() {
        this.routeMapHelperService.removeWaySegments(this.planning_map);
        this.planning_map.fit();
        $('#info_planning_device').text("-");
        $('#info_planning_name').text("-");
        $('#info_planning_distance').text("-").text("-");
        $('#info_planning_date').text("-");
        $('#info_planning_from').text("-");
        $('#info_planning_to').text("-");
        $('#info_planning_info').prop('disabled', true);
        $('#info_planning_edit').prop('disabled', true);
        $('#info_planning_copy').prop('disabled', true);
        $('#info_planning_delete').prop('disabled', true);
        $('#info_planning_send').prop('disabled', true);
    }
    // setInfoMultiplePlannings($plannings: any[]) {
    //   this.resetInfo()
    //   let $txt_device = $('#info_planning_device')
    //   let $txt_routename = $('#info_planning_name')
    //   let $txt_date = $('#info_planning_date')
    //   let $txt_from = $('#info_planning_from')
    //   let $txt_to = $('#info_planning_to')
    //   let $txt_distance = $('#info_planning_distance')
    //   let devices: string[] = []
    //   let routeNames: string[] = []
    //   let minTime, maxTime
    //   let totalDistance = 0
    //   for (const $p in $plannings) {
    //     const $planning = $plannings[$p]
    //     if (!minTime || $planning.RouteFromTime < minTime) minTime = $planning.RouteFromTime
    //     if (!maxTime || $planning.RouteToTime > maxTime) maxTime = $planning.RouteToTime
    //     const $route: ARouteModel | undefined = this.routes_new.find(r => r.RouteId === $planning.RouteId && r.CarNumber === $planning.CarNumber)
    //     const $request: ARouteRequestModel | undefined = this.requests_new.find(r => r.RouteId === $planning.RouteId)
    //     if (!$request || $planning.RouteId == "1" || (!$route && !$request) || !$planning) continue
    //     if (devices.indexOf($planning.Device) === -1) devices.push($planning.Device)
    //     routeNames.push($planning.RouteName)
    //     if ($route) { // route exist
    //       totalDistance += $route.Length
    //       this.drawRouteOnMap(this.planning_map, $route)
    //     } else { // only request availb
    //       totalDistance += this.getParkingDistanceFromWaySegmentIds($request.ParkingStreetIds)
    //       this.drawRequestOnMap(this.planning_map, $request)
    //     }
    //   }
    //   let str_devices = ""
    //   if (devices.length >= 1) {
    //     str_devices = devices[0]
    //     for (let dev = 1; dev < devices.length; dev++) {
    //       str_devices += ", " + devices[dev]
    //     }
    //     if (str_devices.length > 25) $txt_device.text("Multiple Devices")
    //     else $txt_device.text(str_devices)
    //   }
    //   $txt_distance.text(metersToKilometerText(totalDistance))
    //   $txt_routename.text("Multiple Routes")
    //   $txt_date.text(AInputDate(new Date(minTime)))
    //   $txt_from.text(AInputTime(new Date(minTime)))
    //   $txt_to.text(AInputTime(new Date(maxTime)))
    // }
    setInfoPlannings(plannings) {
        this.resetInfo();
        let $txt_device = $('#info_planning_device');
        let $txt_routename = $('#info_planning_name');
        let $txt_date = $('#info_planning_date');
        let $txt_from = $('#info_planning_from');
        let $txt_to = $('#info_planning_to');
        let $txt_distance = $('#info_planning_distance');
        let inPast = false;
        let totalDistance = 0;
        let deviceName = "";
        let routeNames = [];
        let minTime, maxTime;
        for (const planning of plannings) {
            const route = this.routes_new.find(r => r.RouteId === planning.RouteId && r.CarNumber === planning.CarNumber);
            const request = this.requests_new.find(r => r.RouteId === planning.RouteId);
            if (request === undefined)
                continue;
            deviceName = planning.Device; // should be same for all
            if (!inPast)
                inPast = new Date(planning.RouteFromTime) < new Date();
            routeNames.push(planning.RouteName);
            if (!minTime || planning.RouteFromTime < minTime)
                minTime = planning.RouteFromTime;
            if (!maxTime || planning.RouteToTime > maxTime)
                maxTime = planning.RouteToTime;
            if (route) { // route exist
                totalDistance += route.Length;
                this.drawRouteOnMap(this.planning_map, route);
            }
            else { // only request availb
                totalDistance += this.getParkingDistanceFromWaySegmentIds(request.ParkingStreetIds);
                this.drawRequestOnMap(this.planning_map, request);
            }
        }
        $txt_distance.text(metersToKilometerText(totalDistance));
        $txt_device.text(deviceName);
        $txt_routename.text(routeNames.toString());
        if (minTime)
            $txt_date.text(AInputDate(new Date(minTime)));
        if (minTime)
            $txt_from.text(AInputTime(new Date(minTime)));
        if (maxTime)
            $txt_to.text(AInputTime(new Date(maxTime)));
        $('#info_planning_info').prop('disabled', false);
        $('#info_planning_info').off();
        $('#info_planning_info').on('click', _ => this.alert_infoPlanning(plannings));
        $('#info_planning_copy').prop('disabled', false);
        $('#info_planning_copy').off();
        $('#info_planning_copy').on('click', _ => this.alert_copyPlanning(plannings));
        if (!inPast) {
            $('#info_planning_edit').prop('disabled', false);
            $('#info_planning_edit').off();
            $('#info_planning_edit').on('click', _ => this.alert_editPlanning(plannings));
            $('#info_planning_send').prop('disabled', false);
            $('#info_planning_send').off();
            $('#info_planning_send').on('click', _ => this.alert_resendPlanning(plannings));
            $('#info_planning_delete').prop('disabled', false);
            $('#info_planning_delete').off();
            $('#info_planning_delete').on('click', _ => this.alert_removePlanning(plannings));
        }
    }
    // setInfoSingleBreak($planning: ARoutePlanningDeviceRoute) {
    //   this.resetInfo()
    //   let $txt_device = $('#info_planning_device')
    //   let $txt_routename = $('#info_planning_name')
    //   let $txt_date = $('#info_planning_date')
    //   let $txt_from = $('#info_planning_from')
    //   let $txt_to = $('#info_planning_to')
    //   $txt_device.text($planning.Device)
    //   $txt_routename.text($planning.RouteName)
    //   $txt_date.text(AInputDate(new Date($planning.RouteFromTime)))
    //   $txt_from.text(AInputTime(new Date($planning.RouteFromTime)))
    //   $txt_to.text(AInputTime(new Date($planning.RouteToTime)))
    //   $('#info_planning_edit').prop('disabled', false)
    //   $('#info_planning_edit').off()
    //   $('#info_planning_edit').on('click', _ => this.alert_editBreak($planning))
    //   $('#info_planning_delete').prop('disabled', false)
    //   $('#info_planning_delete').off()
    //   $('#info_planning_delete').on('click', _ => this.alert_removeBreak($planning))
    // }
    getParkingDistanceFromWaySegmentIds($waysegmentIds) {
        let distance = 0;
        for (const id in $waysegmentIds) {
            if (this.geoMap.WaySegments[$waysegmentIds[id]] != undefined) {
                distance += this.geoMap.WaySegments[$waysegmentIds[id]].Distance;
            }
        }
        return distance;
    }
    onPlanningClicked(point) {
        const planning = this.plannedRoutes[point.device];
        const route_planned = planning.Routes[point.j];
        //const $route: ARouteModel | undefined = this.routes_new.find(r => r.RouteId === route_planned.RouteId && r.CarNumber === route_planned.CarNumber)
        //const $request: ARouteRequestModel | undefined = this.requests_new.find(r => r.RouteId === route_planned.RouteId)
        // if (!route_planned) return
        // if (route_planned.RouteId == "1") {
        //   this.setInfoSingleBreak(route_planned)
        //   return
        // } else if ((!$route && !$request) || !planning) return
        this.setInfoPlannings([route_planned]);
        //this.setInfoSinglePlanning(route_planned)
        $('#btn_reset_view').prop('disabled', false);
    }
    onPlanningSelected(points) {
        if (points.length == 0)
            return;
        const planningDevice = this.plannedRoutes[points[0].device];
        let selectedPlannings = [];
        for (const point of points) {
            selectedPlannings.push(planningDevice.Routes[point.j]);
        }
        this.setInfoPlannings(selectedPlannings);
    }
    onPlanningDrop(point, RouteFromTime, RouteToTime) {
        const $planning = this.plannedRoutes[point.device];
        const $route_planned = $planning.Routes[point.j];
        this.send_edited_planning($route_planned.ShareId, RouteFromTime, RouteToTime);
    }
    async alert_newPlanningRoutes() {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        var $routehtml = /*html*/ `<select class="form-input" id="routes-dropdown" disabled><option selected value=""></option>`;
        for (const route of Object.values(this.routes_new)) {
            if (route.Active) {
                $routehtml += `<option value=${route.RouteId}>${route.RouteName}</option>`;
            }
        }
        $routehtml += `</select>`;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="devices">${this.translations['Device']}</label>
                  ${deviceshtml}
                  <label class="form-label" for="routes">${this.translations['Route']}</label>
                  ${$routehtml}
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" disabled id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" disabled id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" disabled id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-send" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Send']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['New Planning'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $routes = $('#routes-dropdown');
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        async function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            let routeValid = false;
            $devices.addClass('is-error');
            $routes.prop('disabled', true);
            $routes.removeClass('is-error');
            $date.prop('disabled', true);
            $date.removeClass('is-error');
            $from.prop('disabled', true);
            $from.removeClass('is-error');
            $to.prop('disabled', true);
            $to.removeClass('is-error');
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            if (deviceValid) {
                $routes.prop('disabled', false);
                if ($routes.val()) {
                    routeValid = true;
                }
                else {
                    $routes.addClass('is-error');
                }
            }
            if (routeValid) {
                let duration_route = 0;
                const routeId = $('#routes-dropdown').val();
                const route = $page.routes_new.find(r => r.RouteId === routeId);
                if (route) {
                    duration_route = await Loading.waitForPromises(routePlannerService.fetchRouteDuration(route));
                    //duration_route = estimateRouteDurationFromDistance(route.Length)
                }
                $date.prop('disabled', false);
                $from.prop('disabled', false);
                if ($from.val() && $date.val() && new Date($date.val() + " " + $from.val()) > new Date()) {
                    var $fromdate = new Date($date.val() + " " + $from.val());
                    $fromdate.setSeconds($fromdate.getSeconds() + duration_route);
                    $to.val($fromdate.toTimeString().substring(0, 5));
                    $date.removeClass('is-error');
                    $from.removeClass('is-error');
                    //$to.removeClass('is-error')
                    dateValid = true;
                }
                else {
                    $to.val("-:--");
                    $date.addClass('is-error');
                    $from.addClass('is-error');
                }
            }
            let $sendbtn = $('#btn-send');
            if (deviceValid && routeValid && dateValid) {
                $sendbtn.prop('disabled', false);
            }
            else {
                $sendbtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $('#devices-dropdown').on("change", (e) => {
            verifyInput();
        });
        $('#routes-dropdown').on("change", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            verifyInput();
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        let page = this;
        $('#btn-send').on("click", function () {
            const sendtoname = $('#devices-dropdown').val();
            const routeId = $('#routes-dropdown').val();
            const route = page.routes_new.find(r => r.RouteId === routeId);
            if (!route)
                return;
            let routeName = route.RouteName;
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data = {
                "SendToName": sendtoname,
                "RouteId": route.RouteId,
                "CarNumber": route.CarNumber,
                "RouteName": routeName,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_SendRouteToCar_Response`, response => {
                page.refresh();
            });
            Alerts.closeAllActiveModals();
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_newPlanningRouteArea() {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        // <div class="col-10" style="margin-top: 10px; visibility: hidden;">
        const html = /*html*/ `
    <div>
      <div class="columns">
        <div id="form_1" class="column col-6 col-md-12">
          <div class="col-12 fh">
            <h9 id="alert_estimates_distance"></h9>
            <h9 id="alert_estimates_duration"></h9>

            <label class="form-label" for="devices">${this.translations['Device']}</label>
            ${deviceshtml}

            <div class="columns col col-12">
              <div class="column col-12">
                <label class="form-label">${this.translations['Date Start']}</label>
              </div>
              <div class="column col-6">
                <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date_from">
              </div>
              <div class="column col-6">
                <input class="form-input" type="time" disabled id="planning_time_from">
              </div>              
              <div class="column col-6">
                <label class="form-label">${this.translations['Date Finish']}</label>
              </div>
              <div class="column col-6" style="text-align: left; margin-top: 10px">
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
              </div>
              <div class="column col-6">
                <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date_to">
              </div>
              <div class="column col-6">
                <input class="form-input" type="time" id="planning_time_to">
              </div>
              <div class="column col-12">
                <label class="form-switch" disabled>
                  <input id="regimes_switch" type="checkbox">
                  <i class="form-icon"></i>
                  <label for="regimes_switch">${this.translations['Filter Regimes']}</label>
                </label>
              </div>
            </div>  

            <!--<div class="col-10" style="margin-top: 10px; visibility: hidden;">-->
            <div class="col-12" style="margin-top: 10px;">
              <h8>${this.translations['Sort RouteAreas on']}: </h8>
              <label class="form-switch">
                <input id="lastseen_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="lastseen_switch">${this.translations['Last Enforcement']}</label>
              </label>
              <label class="form-switch">
                <input id="compliancy_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="compliancy_switch">${this.translations['Compliancy']}</label>
              </label>
              <label class="form-switch">
                <input id="occupancy_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="occupancy_switch">${this.translations['Occupancy']}</label>
              </label>
              
              <label class="form-switch">
                <input id="visitorrate_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="visitorrate_switch">${this.translations['Visitor Rate']}</label>
              </label>
              <label class="form-switch">
                <input id="enforcementintensity_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="enforcementintensity_switch">${this.translations['Enforcement Intensity']}</label>
              </label>
            </div>

            <div class="fixTableHead" style="height: 400px; margin-top: 15px;">
              <table class="table">
                <thead>
                  <tr>
                    <th><b>RoutesAreas</b></th>
                  </tr>
                </thead>
                <tbody id="routeareas_list">
                </tbody>
              </table>         
            </div>
          </div>
        </div>

        <div class="column col-6 col-md-12">
          <div style="height: 5%; margin-bottom: 10px">
            <button id="btn-parking-routearea" class="btn"">Show ParkingStreets</button> 
          </div>
          <div id="routearea_map" class="aci-map" style="height: 85%; width: 100%"></div>          
          <div class="legend legend-opaque" id="routearea_map_legend">
            <div id="routearea_map_legend_title" class="legend-label label-height-lg">RouteArea ${this.translations['Name']}</div>
            <div id="routearea_map_legend_content" style="white-space: pre-line;"></div>
          </div>
          <div style="text-align: center; margin-top: 25px;">
            <button id="btn-create" class="btn btn-primary" style="width: 50%;">${this.translations['Create']}</button> 
          </div>      
        </div>
      </div>
    </div>
    `.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['New Planning'],
            content: html,
            type: ALERTS.Mega,
            buttons: ALERT_BUTTONS.okCancel
        });
        function onLowerLayerClicked(type, id) {
            if (type == "routearea") {
                $("#routeareas_list > tr").each(function (index, tr) {
                    if (tr.id == id)
                        tr.click();
                });
            }
        }
        function onLowerLayerMouseOver(type, id) {
            if (type == "routearea" && $page.geoMap.RouteAreas[id]) {
                $('#routearea_map_legend_title').text($page.geoMap.RouteAreas[id].Name);
                $('#routearea_map_legend_content').text(customRowData(id, false));
                $("#routearea_map_legend").toggleClass('hidden', false);
            }
        }
        function onLowerLayerMouseOut() {
            $("#routearea_map_legend").toggleClass('hidden', true);
        }
        let selected_routeareas = [];
        function getOrderNumber($id) {
            let index = selected_routeareas.indexOf($id);
            if (index != -1)
                return (index + 1) + "";
            return "";
        }
        function removeOrderNumber($id) {
            let temp_order_numbers = [];
            for (const i in selected_routeareas) {
                if (selected_routeareas[i] != $id)
                    temp_order_numbers.push(selected_routeareas[i]);
            }
            selected_routeareas = temp_order_numbers;
        }
        function addOrderNumber($id) {
            selected_routeareas.push($id);
        }
        // function getTotalLengthSelectedRouteAreas(): number {
        //   let total_length = 0;
        //   for (const $index in selected_routeareas) {
        //     total_length += estimateRouteDistanceFromParkingStreetDistance($page.geoMap.RouteAreas[selected_routeareas[$index]].DerivedData.ParkingDistance)
        //   }
        //   return total_length;
        // }
        function customRowData($routeAreaId, tooltip) {
            const $routeArea = $page.geoMap.RouteAreas[$routeAreaId];
            if ($routeArea == undefined)
                return "";
            let str = "";
            let newLine = "\n\r";
            if ($switch_regimes.prop('checked') && $page.regimes) {
                let regime = "-";
                for (const r of $page.regimes.RouteAreas[$routeAreaId]) {
                    regime += $page.regimes.Regimes[r] + newLine;
                }
                str += regime;
            }
            if ($switch_lastseen.prop('checked')) {
                let last_seen = "-";
                if ($routeArea.DerivedData.LastSeen) {
                    const new_date = new Date($routeArea.DerivedData.LastSeen);
                    // if date is NaN -> false
                    if (new_date.getTime() === new_date.getTime())
                        last_seen = new_date.toDateString();
                }
                str += $page.translations['Last Enforcement'] + ": " + last_seen + newLine;
            }
            if ($switch_compliancy.prop('checked')) {
                let compliancy_perc = "- ";
                if ($routeArea.DerivedData.Compliancy) {
                    compliancy_perc = Math.round($routeArea.DerivedData.Compliancy * 100) + "% ";
                }
                str += $page.translations['Compliancy'] + ": " + compliancy_perc + newLine;
            }
            if ($switch_occupancy.prop('checked')) {
                let occ_perc = "- ";
                if ($routeArea.DerivedData.Occupancy) {
                    occ_perc = Math.round($routeArea.DerivedData.Occupancy * 100) + "% ";
                }
                str += $page.translations['Occupancy'] + ": " + occ_perc + newLine;
            }
            if ($switch_visitorrate.prop('checked')) {
                let occ_perc = "- ";
                if ($routeArea.DerivedData.VisitorRate) {
                    occ_perc = Math.round($routeArea.DerivedData.VisitorRate * 100) + "% ";
                }
                str += $page.translations['VisitorRate'] + ": " + occ_perc + newLine;
            }
            if ($switch_enforcementintensity.prop('checked')) {
                let occ_perc = "- ";
                if ($routeArea.DerivedData.EnforcementIntensity) {
                    occ_perc = Math.round($routeArea.DerivedData.EnforcementIntensity * 100) + "% ";
                }
                str += $page.translations['Enforcement Intensity'] + ": " + occ_perc + newLine;
            }
            if (tooltip)
                return str.split(" ").join("&#x20;").split("\n\r").join("&#10;");
            return str;
        }
        function sortRouteAreasIds() {
            let routeAreaIds = [];
            let useScore = ($switch_lastseen.prop('checked') || $switch_compliancy.prop('checked') || $switch_occupancy.prop('checked') ||
                $switch_visitorrate.prop('checked') || $switch_enforcementintensity.prop('checked'));
            // Use score as sorting
            for (const routeareaid in $page.geoMap.RouteAreas) {
                const routeArea = $page.geoMap.RouteAreas[routeareaid];
                if ($switch_regimes.prop('checked') && $page.regimes["RouteAreas"]) {
                    //check filter regimes
                    if (!$page.regimes["RouteAreas"][routeareaid]) {
                        routeAreaIds[routeareaid] = {
                            score: 666,
                            id: routeareaid
                        };
                        continue;
                    }
                }
                if (useScore) {
                    if (!routeArea.DerivedData.ParkingDistance || routeArea.DerivedData.ParkingDistance == 0) {
                        routeAreaIds[routeareaid] = {
                            score: 666,
                            id: routeareaid
                        };
                        continue;
                    }
                    let sum_score = 0;
                    let div = 0;
                    if ($switch_lastseen.prop('checked') && routeArea.DerivedData.LastSeenScore) {
                        div++;
                        sum_score += routeArea.DerivedData.LastSeenScore;
                    }
                    if ($switch_compliancy.prop('checked') && routeArea.DerivedData.Compliancy) {
                        div++;
                        sum_score += routeArea.DerivedData.Compliancy;
                    }
                    if ($switch_occupancy.prop('checked') && routeArea.DerivedData.Occupancy) {
                        div++;
                        sum_score += routeArea.DerivedData.Occupancy;
                    }
                    if ($switch_visitorrate.prop('checked') && routeArea.DerivedData.VisitorRate) {
                        div++;
                        sum_score += routeArea.DerivedData.VisitorRate;
                    }
                    if ($switch_enforcementintensity.prop('checked') && routeArea.DerivedData.EnforcementIntensity) {
                        div++;
                        sum_score += routeArea.DerivedData.EnforcementIntensity;
                    }
                    routeAreaIds[routeareaid] = {
                        score: (sum_score > 0 ? sum_score / div : 0),
                        id: routeareaid
                    };
                }
                else { // use name
                    if (!routeArea.DerivedData.ParkingDistance || routeArea.DerivedData.ParkingDistance == 0) {
                        routeAreaIds[routeareaid] = {
                            Name: '\u03A9',
                            id: routeareaid
                        };
                    }
                    else {
                        routeAreaIds[routeareaid] = {
                            Name: routeArea.Name,
                            id: routeareaid
                        };
                    }
                }
            }
            function compare(a, b) {
                const orderNumberA = getOrderNumber(a.id);
                const orderNumberB = getOrderNumber(b.id);
                if (orderNumberA && orderNumberB) {
                    if (orderNumberA < orderNumberB)
                        return -1;
                    if (orderNumberA > orderNumberB)
                        return 1;
                }
                else if (orderNumberA) {
                    return -1;
                }
                else if (orderNumberB) {
                    return 1;
                }
                if (useScore) {
                    if (a.score < b.score) {
                        return -1;
                    }
                    if (a.score > b.score) {
                        return 1;
                    }
                }
                else {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                }
                return 0;
            }
            routeAreaIds.sort(compare);
            return routeAreaIds;
        }
        function fillRouteAreaList(routeAreaIds) {
            let $list = $('#routeareas_list');
            $list.children().remove();
            for (let i in routeAreaIds) {
                let id = routeAreaIds[i].id;
                let selected = (selected_routeareas.indexOf(id) != -1) ? "selected" : "";
                let disabled = (routeAreaIds[i].score == 666 || routeAreaIds[i].Name == '\u03A9') ? "disabled" : "";
                if (selected && disabled) {
                    removeOrderNumber(id);
                }
                let color_txt = "";
                if (disabled) {
                    color_txt = "#E4E4E4";
                    selected = "";
                }
                var Name = "";
                if ($page.geoMap.RouteAreas[id].Name.length) {
                    Name = $page.geoMap.RouteAreas[id].Name;
                }
                if (!Name.length)
                    Name = id;
                var $row = $(/*html*/ `
        <tr id=${id} class='tableRow columns fw ${selected} ${disabled}'>
          <td class="col-10" style="color:${color_txt};" atooltip=${customRowData(id, true)}>${Name}</td>
          <td id="order_number_row" class="col-2" style="text-align: right; color:${color_txt};">${getOrderNumber(id)}</td>
        </tr>
        `.replace(/\s\s+/g, ' '));
                $list.append($row);
            }
        }
        function filterRouteAreas() {
            $page.routeMapHelperService.removePolygonsLowerlayer($page.routearea_map);
            $page.routeMapHelperService.removePolygons($page.routearea_map);
            $page.routeMapHelperService.removeWaySegments($page.routearea_map);
            let routeAreaIds = sortRouteAreasIds();
            fillRouteAreaList(routeAreaIds);
            for (let i in routeAreaIds) {
                let id = routeAreaIds[i].id;
                let disabled = (routeAreaIds[i].score == 666 || routeAreaIds[i].Name == '\u03A9') ? "disabled" : "";
                if (!disabled) {
                    let $score = 1;
                    if (routeAreaIds[i].score != undefined)
                        $score = routeAreaIds[i].score;
                    const red = new AColorHSV(0, 100, 100);
                    const green = new AColorHSV(120, 100, 100);
                    const colorHSV = interpolateService.colorHSV(red, green, $score);
                    const stroke_color = new AColorHSV(0, 0, 32);
                    $page.routeMapHelperService.drawPolygonOnMap($page.routearea_map, $page.geoMap.RouteAreas[id], "routearea", id, colorHSV, stroke_color, 0, onLowerLayerClicked, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    for (const $waySegmentId of $page.geoMap.RouteAreas[id].WaySegments) {
                        const $waySegment = $page.geoMap.WaySegments[$waySegmentId];
                        if ($waySegment && ($waySegment.ParkingCountLeft || $waySegment.ParkingCountRight)) {
                            $page.routeMapHelperService.drawWaySegment_new($page.routearea_map, $waySegment, colorHSV, 3, 5);
                        }
                    }
                }
                else {
                    const colorHSV = new AColorHSV(0, 0, 62);
                    const stroke_color = new AColorHSV(0, 0, 32);
                    $page.routeMapHelperService.drawPolygonOnMap($page.routearea_map, $page.geoMap.RouteAreas[id], "routearea", id, colorHSV, stroke_color, 0, null, null, null);
                }
                $("#routeareas_list > tr").each(function () {
                    $(this).find("#order_number_row").text(getOrderNumber(this.id));
                    if ($(this).hasClass('selected')) {
                        let $item = this;
                        const $id = this.id;
                        const color = new AColorHSV(216, 100, 100);
                        $page.routeMapHelperService.drawPolygonOnMapBorderOnly($page.routearea_map, $page.geoMap.RouteAreas[$id], "routearea", $id, color, 1.0, function () { $item.click(); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            }
            if ($switch_compliancy.prop('checked') ||
                $switch_regimes.prop('checked') ||
                $switch_lastseen.prop('checked') ||
                $switch_occupancy.prop('checked') ||
                $switch_visitorrate.prop('checked') ||
                $switch_enforcementintensity.prop('checked')) {
                initToolTips($('#routeareas_list').find('[atooltip]'), { align: 'center', includeNewlines: true, wordbreak: true });
            }
            $('#routeareas_list').off();
            $('#routeareas_list').on('click', 'tr', function () {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                    removeOrderNumber(this.id);
                }
                else {
                    $(this).addClass('selected');
                    addOrderNumber(this.id);
                }
                let routeAreaIds = sortRouteAreasIds();
                fillRouteAreaList(routeAreaIds);
                $page.routeMapHelperService.removePolygons($page.routearea_map);
                $page.routeMapHelperService.removeNumberMarkers($page.routearea_map);
                $("#routeareas_list > tr").each(function () {
                    const orderNumber = getOrderNumber(this.id);
                    $(this).find("#order_number_row").text(orderNumber);
                    if ($(this).hasClass('selected')) {
                        let $item = this;
                        const $id = this.id;
                        const color = new AColorHSV(216, 100, 100);
                        $page.routeMapHelperService.drawNumberMarker($page.routearea_map, orderNumber, $page.geoMap.RouteAreas[$id].Center);
                        $page.routeMapHelperService.drawPolygonOnMapBorderOnly($page.routearea_map, $page.geoMap.RouteAreas[$id], "routearea", $id, color, 1.0, function () { $item.click(); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            });
            if ($parkingstreets_visible) {
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.1);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, true);
            }
            else {
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.5);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, false);
            }
        }
        async function verifyInput() {
            let routeAreasValid = false;
            let deviceValid = false;
            let dateValid = false;
            routeAreasValid = ($("#routeareas_list > tr.selected").length > 0);
            let $devices = $('#devices-dropdown');
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($from_date.val() && (new Date($from_date.val() + " " + $from_time.val()) > new Date())) {
                $from_date.removeClass('is-error');
                $from_time.removeClass('is-error');
                dateValid = true;
            }
            else {
                $from_date.addClass('is-error');
                $from_time.addClass('is-error');
            }
            if (routeAreasValid) {
                let total_parkingdistance = 0;
                for (const $index in selected_routeareas) {
                    total_parkingdistance += $page.geoMap.RouteAreas[selected_routeareas[$index]].DerivedData.ParkingDistance;
                }
                const est_length_duration = await Loading.waitForPromises(routePlannerService.fetchRouteDurationParkingStreetDistance(total_parkingdistance));
                $estimates_distance.text($page.translations['Estimate length'] + ": " + metersToKilometerText(est_length_duration.length));
                $estimates_duration.text(" " + $page.translations['Estimate duration'] + ": " + secondsToDurationTextHHMM(est_length_duration.duration));
                if (dateValid) {
                    var $fromdate = new Date($from_date.val() + " " + $from_time.val());
                    var $todate = $fromdate;
                    $todate.setSeconds($todate.getSeconds() + est_length_duration.duration);
                    $to_date.val(moment($todate).format("YYYY-MM-DD"));
                    $to_time.val(moment($todate).format("HH:mm"));
                }
            }
            else {
                $estimates_distance.text($page.translations['Estimate length'] + ": 0 km");
                $estimates_duration.text(" " + $page.translations['Estimate duration'] + ": 0m");
                $to_date.val(0);
                $to_time.val("-:--");
            }
            if (routeAreasValid && deviceValid && dateValid) {
                $createbtn.prop('disabled', false);
            }
            else {
                $createbtn.prop('disabled', true);
            }
        }
        async function sendNewRequestSelectedRouteAreas() {
            let $fromTime = new Date($from_date.val() + " " + $from_time.val());
            for (const index in selected_routeareas) {
                let $routeareaId = parseInt(selected_routeareas[index]);
                let $routearea = $page.geoMap.RouteAreas[$routeareaId];
                let $parkingptreetIds = [];
                for (const ws_index in $routearea.WaySegments) {
                    if ($routearea.WaySegments[ws_index] && $page.geoMap.WaySegments[$routearea.WaySegments[ws_index]]) {
                        if ($page.geoMap.WaySegments[$routearea.WaySegments[ws_index]].ParkingCountLeft || $page.geoMap.WaySegments[$routearea.WaySegments[ws_index]].ParkingCountRight) {
                            $parkingptreetIds.push($routearea.WaySegments[ws_index]);
                        }
                    }
                }
                let $toTime = new Date($fromTime);
                const est_length_duration = await Loading.waitForPromises(routePlannerService.fetchRouteDurationParkingStreetDistance($page.geoMap.RouteAreas[selected_routeareas[index]].DerivedData.ParkingDistance));
                $toTime.setSeconds($toTime.getSeconds() + est_length_duration.duration);
                const Settings = {
                    Areas: [],
                    Zones: [],
                    RouteAreas: [$routeareaId],
                    AllStreets: false,
                    WithoutStatistics: true,
                    MinOccupancy: 0,
                    MaxOccupancy: 1,
                    MinVisitorRate: 0,
                    MaxVisitorRate: 1,
                    MinCompliancy: 0,
                    MaxCompliancy: 1,
                    MinCompliancyVisitors: 0,
                    MaxCompliancyVisitors: 1,
                    MinEnforcementIntensity: 0,
                    MaxEnforcementIntensity: 1,
                };
                const request = {
                    "RouteName": $routearea.Name,
                    "ParkingStreetIds": $parkingptreetIds,
                    "Settings": Settings,
                    "NumRoutes": 1,
                    "QualityLevel": 3,
                };
                const $existingRequest = await Loading.waitForPromises(routePlannerService.existingRouteRequest(request));
                if ($existingRequest != undefined && $existingRequest.RouteId && $existingRequest.RouteName) { // Use existing route
                    const data = {
                        "ProjectNr": CCCClient.NodeProjectNumber,
                        "CustomerNr": CCCClient.NodeCustomerNumber,
                        "SendToName": $('#devices-dropdown').val(),
                        "RouteId": $existingRequest.RouteId,
                        "CarNumber": 0,
                        "RouteName": $existingRequest.RouteName,
                        "RouteFromTime": $fromTime,
                        "RouteToTime": $toTime
                    };
                    CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data, 0, {
                        Type: "ControlCenter",
                        IndexNumber: 1,
                        CustomerNumber: CCCClient.NodeCustomerNumber,
                        ProjectNumber: CCCClient.NodeProjectNumber
                    });
                }
                else { // New request 
                    const routename = $routearea.Name;
                    const fordevice = $('#devices-dropdown').val();
                    const preferright = true;
                    const numroutes = 1;
                    // default values
                    const maxvalue = 1000;
                    const maxiteration = 20000;
                    const initialcoolingrate = 1000;
                    const planrequest = {
                        "RouteName": routename,
                        "ForDevice": fordevice,
                        "PreferRight": preferright,
                        "ParkingStreetIds": $parkingptreetIds,
                        "Settings": Settings,
                        "NumRoutes": numroutes,
                        "MaxValue": maxvalue,
                        "MaxIteration": maxiteration,
                        "InitialCoolingRate": initialcoolingrate,
                        "RouteFromTime": $fromTime,
                        "RouteToTime": $toTime
                    };
                    Loading.waitForPromises(new Promise((resolve, reject) => {
                        CCCClient.SendMessage("PlanRoute_CreateRoute_Request", 0, planrequest, 0, {
                            Type: "ControlCenter",
                            IndexNumber: 1,
                            CustomerNumber: CCCClient.NodeCustomerNumber,
                            ProjectNumber: CCCClient.NodeProjectNumber
                        });
                        Events.once(`PlanRoute_CreateRoute_Response`, response => {
                            if (response.message == 'ok') {
                                resolve(true);
                            }
                        });
                        sleep(5000).then(() => {
                            reject();
                        });
                    }));
                }
                $fromTime = $toTime;
                //$fromTime.setMinutes($fromTime.getMinutes() + 1)
            }
        }
        let $createbtn = $('#btn-create');
        $createbtn.prop('disabled', true);
        let $parkingrouteareabtn = $('#btn-parking-routearea');
        let $estimates_distance = $('#alert_estimates_distance');
        let $estimates_duration = $('#alert_estimates_duration');
        $page.routearea_map = createMap('routearea_map', { zoom: 100 });
        $page.routearea_map.fit();
        AEngine.get(AMapOverlayService).add(this.routearea_map, this.routearea_map.getDiv(), $("#routearea_map_legend").toggleClass('hidden', true), MAP_POSITION.BOTTOM_LEFT);
        let $switch_regimes = $('#regimes_switch');
        let $switch_lastseen = $('#lastseen_switch');
        let $switch_compliancy = $('#compliancy_switch');
        let $switch_occupancy = $('#occupancy_switch');
        let $switch_visitorrate = $('#visitorrate_switch');
        let $switch_enforcementintensity = $('#enforcementintensity_switch');
        let $from_date = $("#planning_date_from");
        let $from_time = $("#planning_time_from");
        $switch_enforcementintensity.prop('checked', false);
        let $to_date = $("#planning_date_to");
        let $to_time = $("#planning_time_to");
        let $parkingstreets_visible = false;
        $switch_regimes.prop('checked', false);
        $switch_lastseen.prop('checked', false);
        $switch_compliancy.prop('checked', false);
        $switch_occupancy.prop('checked', false);
        $switch_visitorrate.prop('checked', false);
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $from_date.prop('min', minDate);
        $('#devices-dropdown').on("change", (e) => {
            verifyInput();
        });
        $to_date.prop("readonly", true);
        $to_time.prop("readonly", true);
        $from_date.on("change", async (e) => {
            if ($from_time.prop('disabled')) {
                $from_time.prop('disabled', false);
                $from_time.val("06:00");
            }
            if ($from_date.val() == minDate) {
                let other_date = now;
                other_date.setMinutes(other_date.getMinutes() + 1);
                $from_time.val(moment(other_date).format("HH:mm"));
            }
            const $fromdate = new Date($from_date.val() + " " + $from_time.val());
            let $todate = new Date($fromdate);
            $todate.setHours($todate.getHours() + 2);
            $page.regimes = await Loading.waitForPromises(routePlannerService.fetchAndCacheRegime($fromdate, $todate));
            filterRouteAreas();
            verifyInput();
        });
        $from_time.on("change", async (e) => {
            const $fromdate = new Date($from_date.val() + " " + $from_time.val());
            let total_parkingdistance = 0;
            for (const $index in selected_routeareas) {
                total_parkingdistance += $page.geoMap.RouteAreas[selected_routeareas[$index]].DerivedData.ParkingDistance;
            }
            const est_length_duration = await Loading.waitForPromises(routePlannerService.fetchRouteDurationParkingStreetDistance(total_parkingdistance));
            let $total_duration = est_length_duration.duration;
            let $todate = new Date($fromdate);
            $todate.setSeconds($todate.getSeconds() + $total_duration);
            $to_date.val(moment($todate).format("YYYY-MM-DD"));
            $to_time.val(moment($todate).format("HH:mm"));
            if ($total_duration == 0)
                $todate.setHours($todate.getHours() + 2); // if nothing selected, get 2 hours
            this.regimes = await Loading.waitForPromises(routePlannerService.fetchAndCacheRegime($fromdate, $todate));
            filterRouteAreas();
            verifyInput();
        });
        //   verifyInput()
        // });
        $switch_regimes.on('change', filterRouteAreas);
        $switch_lastseen.on('change', filterRouteAreas);
        $switch_compliancy.on('change', filterRouteAreas);
        $switch_occupancy.on('change', filterRouteAreas);
        $switch_visitorrate.on('change', filterRouteAreas);
        $switch_enforcementintensity.on('change', filterRouteAreas);
        filterRouteAreas();
        verifyInput();
        $page.routeMapHelperService.fitBoundsPolygonsLowerLayer($page.routearea_map);
        $createbtn.on("click", async () => {
            const $fromTime = new Date($from_date.val() + " " + $from_time.val());
            const $toTime = new Date($to_date.val() + " " + $to_time.val());
            const $device = $('#devices-dropdown').val();
            const overlappingPlanning = await Loading.waitForPromises(routePlannerService.fetchPlannerRoutesOverlap($fromTime, $toTime, $device));
            if (!overlappingPlanning.length || (overlappingPlanning && overlappingPlanning.length && await this.alert_sendOverlap(overlappingPlanning))) {
                await Loading.waitForPromises(sendNewRequestSelectedRouteAreas()).then(async (response) => {
                    // TODO: Code Review /w Ivan
                    this.routes_new = await routePlannerService.fetchRoutes();
                    this.requests_new = await routePlannerService.fetchRequests();
                }).then(response => {
                    this.refresh();
                    Alerts.closeAllActiveModals();
                });
            }
        });
        $parkingrouteareabtn.on("click", async () => {
            if ($parkingstreets_visible) {
                $parkingrouteareabtn.text("Show ParkingStreets");
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.5);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, false);
                $parkingstreets_visible = false;
            }
            else {
                $parkingrouteareabtn.text("Show Heatmap");
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.1);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, true);
                $parkingstreets_visible = true;
            }
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_newBreak() {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="devices">${this.translations['Device']}</label>
                  ${deviceshtml}
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Add Break'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date() && $from.val() < $to.val())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let $savebtn = $('#btn-save');
            if (deviceValid && dateValid) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        //$devices.val($planning.Device);
        //$date.val(moment(new Date($planning.RouteFromTime)).format('yyyy-MM-DD'))
        //$from.val(moment(new Date($planning.RouteFromTime)).format('HH:mm'))
        //$to.val(moment(new Date($planning.RouteToTime)).format('HH:mm'))
        $devices.on("change", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            if ($from.prop('disabled')) {
                $from.prop('disabled', false);
                $to.prop('disabled', false);
            }
            verifyInput();
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        $to.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", function () {
            const sendtoname = $('#devices-dropdown').val();
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data_new = {
                "SendToName": sendtoname,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_AddBreak_Request", 0, data_new, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_AddBreak_Response`, response => {
                $page.refresh();
            });
            Alerts.closeAllActiveModals();
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_copyPlanning(plannings) {
        if (plannings.length == 0)
            return;
        let routeList = ``;
        let minDate = plannings[0].RouteFromTime;
        let maxDate = plannings[0].RouteToTime;
        for (const planning of plannings) {
            routeList += `<li>${planning.RouteName}</li>`;
            if (planning.RouteFromTime < minDate)
                minDate = planning.RouteFromTime;
            if (planning.RouteToTime > maxDate)
                maxDate = planning.RouteToTime;
        }
        const total_duration = (new Date(maxDate).getTime() - new Date(minDate).getTime()) / 1000;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="routes">${this.translations['Route']}</label>
                  <ul>${routeList}</ul>
                  <div>${this.translations['Duration']}: ${secondsToDurationTextHHMM(total_duration)}</div>
                  <label class="form-label" style="margin-top: 10px">${this.translations['Copy to'] + ' ' + this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Copy Planning'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        function verifyInput() {
            let dateValid = false;
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let changed = (new Date($date.val() + " " + $from.val()).toString() != new Date(minDate).toString()) ||
                (new Date($date.val() + " " + $to.val()).toString() != new Date(maxDate).toString());
            let $savebtn = $('#btn-save');
            if (dateValid && changed) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDateInput = now.toISOString().substring(0, 10);
        $date.prop('min', minDateInput);
        $date.val(moment(new Date(minDate)).format('yyyy-MM-DD'));
        $from.val(moment(new Date(minDate)).format('HH:mm'));
        $to.val(moment(new Date(maxDate)).format('HH:mm'));
        $to.prop("readonly", true);
        $date.on("change", (e) => {
            if ($from.prop('disabled')) {
                $from.prop('disabled', false);
                $to.prop('disabled', false);
                $from.val("06:00");
                var $fromdate = new Date($date.val() + " " + $from.val());
                $fromdate.setSeconds($fromdate.getSeconds() + total_duration);
                $to.val($fromdate.toTimeString().substring(0, 5));
            }
            if ($date.val() == minDate) {
                let other_date = now;
                other_date.setMinutes(other_date.getMinutes() + 1);
                $from.val(other_date.toTimeString().substring(0, 5));
                var $fromdate = new Date($date.val() + " " + $from.val());
                $fromdate.setSeconds($fromdate.getSeconds() + total_duration);
                $to.val($fromdate.toTimeString().substring(0, 5));
            }
            verifyInput();
        });
        $from.on("change", (e) => {
            var $fromdate = new Date($date.val() + " " + $from.val());
            $fromdate.setSeconds($fromdate.getSeconds() + total_duration);
            $to.val($fromdate.toTimeString().substring(0, 5));
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", async () => {
            const $fromTime = new Date($date.val() + " " + $from.val());
            const $toTime = new Date($date.val() + " " + $from.val());
            $toTime.setSeconds($toTime.getSeconds() + total_duration);
            const overlappingPlanning = await Loading.waitForPromises(routePlannerService.fetchPlannerRoutesOverlap($fromTime, $toTime, plannings[0].Device));
            if (!overlappingPlanning.length || (overlappingPlanning && overlappingPlanning.length && await this.alert_sendOverlap(overlappingPlanning))) {
                const timeOffset = new Date($date.val() + " " + $from.val()).getTime() - new Date(minDate).getTime();
                for (const planning of plannings) {
                    const NewRouteFromTime = new Date(new Date(planning.RouteFromTime).getTime() + timeOffset);
                    const NewRouteToTime = new Date(new Date(planning.RouteToTime).getTime() + timeOffset);
                    const data = {
                        "ProjectNr": CCCClient.NodeProjectNumber,
                        "CustomerNr": CCCClient.NodeCustomerNumber,
                        "SendToName": planning.Device,
                        "RouteId": planning.RouteId,
                        "CarNumber": planning.CarNumber,
                        "RouteName": planning.RouteName,
                        "RouteFromTime": NewRouteFromTime,
                        "RouteToTime": NewRouteToTime
                    };
                    CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data, 0, {
                        Type: "ControlCenter",
                        IndexNumber: 1,
                        CustomerNumber: CCCClient.NodeCustomerNumber,
                        ProjectNumber: CCCClient.NodeProjectNumber
                    });
                }
                this.refresh();
                Alerts.closeAllActiveModals();
            }
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_editPlanning(plannings) {
        if (plannings.length == 0)
            return;
        let routeList = ``;
        let shareIds = [];
        let minDate = plannings[0].RouteFromTime;
        let maxDate = plannings[0].RouteToTime;
        for (const planning of plannings) {
            routeList += `<li>${planning.RouteName}</li>`;
            if (planning.RouteFromTime < minDate)
                minDate = planning.RouteFromTime;
            if (planning.RouteToTime > maxDate)
                maxDate = planning.RouteToTime;
            shareIds.push(planning.ShareId);
        }
        const total_duration = (new Date(maxDate).getTime() - new Date(minDate).getTime()) / 1000;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="routes">${this.translations['Route']}</label>
                  <ul>${routeList}</ul>
                  <div>${this.translations['Duration']}: ${secondsToDurationTextHHMM(total_duration)}</div>
                  <label class="form-label" style="margin-top: 10px">${this.translations['New'] + " " + this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Edit Planning'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        function verifyInput() {
            let dateValid = false;
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let changed = (new Date($date.val() + " " + $from.val()).toString() != new Date(minDate).toString()) ||
                (new Date($date.val() + " " + $to.val()).toString() != new Date(maxDate).toString());
            let $savebtn = $('#btn-save');
            if (dateValid && changed) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDateInput = now.toISOString().substring(0, 10);
        $date.prop('min', minDateInput);
        $date.val(moment(new Date(minDate)).format('yyyy-MM-DD'));
        $from.val(moment(new Date(minDate)).format('HH:mm'));
        $to.val(moment(new Date(maxDate)).format('HH:mm'));
        $to.prop("readonly", true);
        $date.on("change", (e) => {
            if ($from.prop('disabled')) {
                $from.prop('disabled', false);
                $to.prop('disabled', false);
                $from.val("06:00");
                var $fromdate = new Date($date.val() + " " + $from.val());
                $fromdate.setSeconds($fromdate.getSeconds() + total_duration);
                $to.val($fromdate.toTimeString().substring(0, 5));
            }
            if ($date.val() == minDate) {
                let other_date = now;
                other_date.setMinutes(other_date.getMinutes() + 1);
                $from.val(other_date.toTimeString().substring(0, 5));
                var $fromdate = new Date($date.val() + " " + $from.val());
                $fromdate.setSeconds($fromdate.getSeconds() + total_duration);
                $to.val($fromdate.toTimeString().substring(0, 5));
            }
            verifyInput();
        });
        $from.on("change", (e) => {
            var $fromdate = new Date($date.val() + " " + $from.val());
            $fromdate.setSeconds($fromdate.getSeconds() + total_duration);
            $to.val($fromdate.toTimeString().substring(0, 5));
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", async () => {
            const $fromTime = new Date($date.val() + " " + $from.val());
            const $toTime = new Date($date.val() + " " + $from.val());
            $toTime.setSeconds($toTime.getSeconds() + total_duration);
            const overlappingPlanning = await Loading.waitForPromises(routePlannerService.fetchPlannerRoutesOverlap($fromTime, $toTime, plannings[0].Device));
            const overlappingPlanning_minus_current = overlappingPlanning.filter(result => plannings.map(planning => planning.ShareId).indexOf(result.ShareId) == -1);
            if (!overlappingPlanning_minus_current.length || (overlappingPlanning_minus_current && overlappingPlanning_minus_current.length && await this.alert_sendOverlap(overlappingPlanning_minus_current))) {
                const timeOffset = new Date($date.val() + " " + $from.val()).getTime() - new Date(minDate).getTime();
                for (const planning of plannings) {
                    const NewRouteFromTime = new Date(new Date(planning.RouteFromTime).getTime() + timeOffset);
                    const NewRouteToTime = new Date(new Date(planning.RouteToTime).getTime() + timeOffset);
                    Loading.waitForPromises(this.send_edited_planning(planning.ShareId, NewRouteFromTime, NewRouteToTime));
                }
                this.refresh();
            }
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_editBreak($planning) {
        let $page = this;
        if ($planning.RouteId != "1")
            return;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="break_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="break_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="break_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Edit Break'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $date = $("#break_date");
        let $from = $("#break_fromtime");
        let $to = $("#break_totime");
        function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date() && $from.val() < $to.val())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let changed = ($devices.val() != $planning.Device) ||
                (new Date($date.val() + " " + $from.val()).toString() != new Date($planning.RouteFromTime).toString()) ||
                (new Date($date.val() + " " + $to.val()).toString() != new Date($planning.RouteToTime).toString());
            let $savebtn = $('#btn-save');
            if (deviceValid && dateValid && changed) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $devices.val($planning.Device);
        $date.val(moment(new Date($planning.RouteFromTime)).format('yyyy-MM-DD'));
        $from.val(moment(new Date($planning.RouteFromTime)).format('HH:mm'));
        $to.val(moment(new Date($planning.RouteToTime)).format('HH:mm'));
        $devices.on("change", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            verifyInput();
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        $to.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", function () {
            const data_old = {
                "ShareId": $planning.ShareId
            };
            CCCClient.SendMessage("PlanRoute_DeleteBreakByShareId_Request", 0, data_old, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            const sendtoname = $('#devices-dropdown').val();
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data_new = {
                "SendToName": sendtoname,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_AddBreak_Request", 0, data_new, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_AddBreak_Response`, response => {
                $page.refresh();
                Alerts.closeAllActiveModals();
            });
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_resendPlanning(plannings) {
        if (plannings.length == 0)
            return;
        let routeList = ``;
        let shareIds = [];
        for (const planning of plannings) {
            routeList += `<li>${planning.RouteName}</li>`;
            shareIds.push(planning.ShareId);
        }
        const html = /*html*/ `
      <div class="form-group">
        <label class="form-label">${this.translations['Resend Planning']}:</label>
        <ul>${routeList}</ul>
        <label class="form-label">${this.translations['To Car']}: '${plannings[0].Device}'?</label>
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Resend Planning'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.okCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = {
                "ShareIds": shareIds
            };
            CCCClient.SendMessage("PlanRoute_ResendRoutesToCarByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
        });
    }
    async alert_removePlanning(plannings) {
        let routeNames = [];
        let shareIds = [];
        for (const planning of plannings) {
            routeNames.push(planning.RouteName);
            shareIds.push(planning.ShareId);
        }
        const html = /*html*/ `
      <div id="${routeNames.toString()}" class="form-group">
              <label class="form-label">${this.translations['Delete Planning']}: '${routeNames.toString()}'?</label>
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Delete Planning'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = {
                "ShareIds": shareIds
            };
            CCCClient.SendMessage("PlanRoute_DeleteRoutesFromCarByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_DeleteRoutesFromCarByShareId_Response`, response => {
                this.refresh();
            });
        });
    }
    async alert_removeBreak($planning) {
        const html = /*html*/ `
      <div id="${$planning.RouteId}" class="form-group">
              <label class="form-label">${this.translations['Delete Break']}?</label>
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Delete Break'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = {
                "ShareId": $planning.ShareId
            };
            CCCClient.SendMessage("PlanRoute_DeleteBreakByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_DeleteBreakByShareId_Response`, response => {
                this.refresh();
            });
        });
    }
    async alert_infoPlanning(plannings) {
        if (plannings.length == 0)
            return;
        let RouteNames = [];
        let settings = [];
        let strGeoDataTimeStamp = "-";
        let AreaNames = [];
        let ZoneNames = [];
        let RouteAreaNames = [];
        for (const planning of plannings) {
            const request = this.requests_new.find(r => r.RouteId === planning.RouteId);
            const $route = this.routes_new.find(r => r.RouteId === planning.RouteId && r.CarNumber === planning.CarNumber);
            if (!request)
                continue;
            RouteNames.push(planning.RouteName);
            if ($route)
                strGeoDataTimeStamp = $route.GeoDataTimeStamp;
            const setting = request.Settings;
            settings.push(setting);
            for (const areaId of setting.Areas) {
                if (this.geoMap.Areas[areaId])
                    AreaNames.push(this.geoMap.Areas[areaId].Name);
            }
            for (const zoneId of setting.Zones) {
                if (this.geoMap.Zones[zoneId])
                    ZoneNames.push(this.geoMap.Zones[zoneId].Name);
            }
            for (const routeAreaId of setting.RouteAreas) {
                if (this.geoMap.RouteAreas[routeAreaId])
                    RouteAreaNames.push(this.geoMap.RouteAreas[routeAreaId].Name);
            }
        }
        let areazoneHtml = ``;
        if (AreaNames.length && !ZoneNames.length && !RouteAreaNames.length) {
            areazoneHtml += `<h6>Areas</h6><h9>${AreaNames.join(',')}</h9>`;
        }
        else if (!AreaNames.length && ZoneNames.length && !RouteAreaNames.length) {
            areazoneHtml += `<h6>Zones</h6><h9>${ZoneNames.join(',')}</h9>`;
        }
        else if (!AreaNames.length && !ZoneNames.length && RouteAreaNames.length) {
            areazoneHtml += `<h6>RouteAreas</h6><h9>${RouteAreaNames.join(',')}</h9>`;
        }
        else {
            if (AreaNames.length)
                areazoneHtml += `<h9> Areas: ${AreaNames.join(',')} </h9>`;
            if (ZoneNames.length)
                areazoneHtml += `<h9> Zones: ${ZoneNames.join(',')} </h9>`;
            if (RouteAreaNames.length)
                areazoneHtml += `<h9> RouteAreas: ${RouteAreaNames.join(',')}</h9>`;
        }
        const MinOccupancy = Math.min(...settings.map(setting => setting.MinOccupancy));
        const MaxOccupancy = Math.max(...settings.map(setting => setting.MaxOccupancy));
        const MinVisitorRate = Math.min(...settings.map(setting => setting.MinVisitorRate));
        const MaxVisitorRate = Math.max(...settings.map(setting => setting.MaxVisitorRate));
        const MinCompliancy = Math.min(...settings.map(setting => setting.MinCompliancy));
        const MaxCompliancy = Math.max(...settings.map(setting => setting.MaxCompliancy));
        const MinCompliancyVisitors = Math.min(...settings.map(setting => setting.MinCompliancyVisitors));
        const MaxCompliancyVisitors = Math.max(...settings.map(setting => setting.MaxCompliancyVisitors));
        const MinEnforcementIntensity = Math.min(...settings.map(setting => setting.MinEnforcementIntensity));
        const MaxEnforcementIntensity = Math.max(...settings.map(setting => setting.MaxEnforcementIntensity));
        const html = /*html*/ `
      <div class="columns" style="min-height: 300px; overflow-y: auto;">
        
        <div class="col-12">
          ${areazoneHtml}
        </div>

        <div class="col-12">
          <h6>GeoDataTimeStamp</h6>
          <h9>${strGeoDataTimeStamp}</h9>
        </div>
        
        
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Occupancy']}</h6>
          <h9>${MinOccupancy * 100 + "% - " + MaxOccupancy * 100 + "%"}</h9>          
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['VisitorRate']}</h6>
          <h9>${MinVisitorRate * 100 + "% - " + MaxVisitorRate * 100 + "%"}</h9>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Compliancy']}</h6>
          <h9>${MinCompliancy * 100 + "% - " + MaxCompliancy * 100 + "%"}</h9>          
        </div>
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['CompliancyVisitors']}</h6>
          <h9>${MinCompliancyVisitors * 100 + "% - " + MaxCompliancyVisitors * 100 + "%"}</h9>          
        </div>  
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['EnforcementIntensity']}</h6>
          <h9>${MinEnforcementIntensity * 100 + "% - " + MaxEnforcementIntensity * 100 + "%"}</h9>          
        </div> 
    </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: plannings.map(planning => planning.RouteName).join(','),
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.ok
        });
    }
    async exportCSV() {
        let csv_string = [
            [
                await Translate.get("Device"),
                await Translate.get("Date"),
                await Translate.get("Time"),
                await Translate.get("RouteName")
            ]
        ];
        if (this.plannedRoutes) {
            for (const scanDevice of Object.keys(this.plannedRoutes)) {
                let ScanDevice = this.plannedRoutes[scanDevice];
                if (ScanDevice.Routes) {
                    for (const route of Object.keys(ScanDevice.Routes)) {
                        const r = ScanDevice.Routes[route];
                        csv_string.push([
                            r.Device,
                            AInputDate(new Date(r.RouteFromTime)),
                            AInputTime(new Date(r.RouteFromTime)) + " - " + AInputTime(new Date(r.RouteToTime)),
                            r.RouteName
                        ]);
                    }
                }
            }
        }
        const filter = FilterManager.saveExplicit();
        let from = AInputDateTime(new Date(filter.FromDate));
        let to = AInputDateTime(new Date(filter.ToDate));
        const filename = `Routes Planning ${from} - ${to}.csv`;
        // Create blob in order to export csv data
        const file = new Blob(['\ufeff' + csv_string.map(e => e.join(",")).join("\r\n")], { type: 'text/csv;charset=utf-8;' });
        // Create temporary url to export file
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.innerHTML = "Click Here to download";
        document.body.appendChild(link); // Required for FF
        link.click();
        setTimeout(() => {
            // Delete temporary download href
            link.remove();
            // Deconstruct temporary file url
            URL.revokeObjectURL(url);
        }, 0);
    }
    transformPlannedRoutes() {
        const output = [];
        let y = 0;
        for (const i in this.plannedRoutes) {
            const $planning_device = this.plannedRoutes[i];
            for (const j in $planning_device.Routes) {
                const $route = $planning_device.Routes[j];
                const FromDate = new Date($route.RouteFromTime);
                const ToDate = new Date($route.RouteToTime);
                output.push({
                    x: FromDate.getTime(),
                    x2: ToDate.getTime(),
                    y: y,
                    j: j,
                    duration: AConvertMillisecondsToHM(ToDate.getTime() - FromDate.getTime()),
                    device: $planning_device.Device,
                    routename: $route.RouteName,
                    routeId: $route.RouteId,
                    plannedRouteIndex: i,
                    partialFill: 0.99
                });
            }
            y++;
        }
        return output;
    }
    drawRequestOnMap($map, $request) {
        if (this.geoMap == undefined || this.geoMap.WaySegments == undefined)
            return;
        let WaysegmentsParking = [];
        if ($request.ParkingStreetIds != undefined && $request.ParkingStreetIds.length) {
            for (const i in $request.ParkingStreetIds) {
                let WaySegmentId = Math.abs($request.ParkingStreetIds[i]);
                if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                    WaysegmentsParking.push(WaySegmentId);
                    if (this.geoMap.WaySegments[WaySegmentId])
                        this.routeMapHelperService.drawWaySegment_new($map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red, 3.0, 7.0);
                    //this.routeMapHelperService.drawWaySegmentRed($map, this.geoMap.WaySegments[WaySegmentId], 1.0)
                }
            }
            this.routeMapHelperService.fitBoundsWaySegments($map);
        }
    }
    drawRouteOnMap($map, $route) {
        if (this.geoMap == undefined || this.geoMap.WaySegments == undefined)
            return;
        let WaysegmentsParking = [];
        let WaysegmentsOther = [];
        if ($route.RouteSegments != undefined && $route.RouteSegments.length) {
            for (const i in $route.RouteSegments) {
                const $s = $route.RouteSegments[i];
                let WaySegmentId = Math.abs($s.WaySegmentId);
                if ($s.ScanSide === "ScanLeft" || $s.ScanSide === "ScanRight" || $s.ScanSide === "ScanLeftAndRight" || $s.ScanSide === "ScanAny") {
                    if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                        WaysegmentsParking.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment_new($map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red, 3.0, 7.0);
                        //this.routeMapHelperService.drawWaySegmentRed($map, this.geoMap.WaySegments[WaySegmentId], 1.0)
                    }
                }
                else {
                    if (WaysegmentsOther.indexOf(WaySegmentId) === -1) {
                        WaysegmentsOther.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment_new($map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.orange, 2.0, 7.0);
                        //this.routeMapHelperService.drawWaySegmentOrange($map, this.geoMap.WaySegments[WaySegmentId], 1.0)
                    }
                }
            }
            this.routeMapHelperService.fitBoundsWaySegments($map);
        }
    }
    async alert_sendOverlap(response) {
        let $routeList = ``;
        for (const row in response) {
            const cont = response[row];
            const fromTime = AInputTime(new Date(new Date(cont.RouteFromTime)));
            const toTime = AInputTime(new Date(new Date(cont.RouteToTime)));
            $routeList += `<li style="margin-left: 2px; list-style-position: outside;">                       
                      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 300px;">
                      ${fromTime} - ${toTime}  ${cont.RouteName}
                      </div>
                     </li>`;
        }
        const html = /*html*/ `
      <div class="form-group">
           <div>${this.translations['New Planning Overlaps With']}:</div>
           <ul>
            ${$routeList}
           </ul>
          <div>${this.translations['Continue']}?</div>
      </div>`.replace(/\s\s+/g, ' ');
        const alert = Alerts.show({
            translatedTitle: this.translations['Warning: Overlapping Planning'],
            buttons: ALERT_BUTTONS.okCancel,
            content: html
        });
        return new Promise((resolve) => {
            alert.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                resolve(true);
            });
            alert.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {
                resolve(false);
            });
        });
    }
    async send_edited_planning(shareid, routefromtime, routetotime) {
        const data = {
            "ShareId": shareid,
            "RouteFromTime": routefromtime,
            "RouteToTime": routetotime
        };
        CCCClient.SendMessage("PlanRoute_EditRouteFromToTimeFromCarByShareId_Request", 0, data, 0, {
            Type: "ControlCenter",
            IndexNumber: 1,
            CustomerNumber: CCCClient.NodeCustomerNumber,
            ProjectNumber: CCCClient.NodeProjectNumber
        });
        Events.once(`PlanRoute_EditRouteFromToTimeFromCarByShareId_Response`, response => {
            Alerts.closeAllActiveModals();
            if (response.message == "error") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: 'Error',
                    content: response.error
                });
                return Promise.resolve(false);
            }
            else if (response.message == "ok") {
                return Promise.resolve(true);
            }
        });
        return Promise.resolve(true);
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .fixTableHead {
        overflow-y: auto;
        height: 100%;
      }
      .fixTableHead thead th {
        position: sticky;
        background: #F9F9F9;
        top: 0;
      }
      .tableRow {
        background: #ffffff;
      }
      .tableRow:hover {
        background: #F9F9F9;
      }
      .tableRow.selected {
        background: #ECECEC;
      }

      .tableRow.disabled{
        pointer-events: none;
      }

      .footer {
        background: rgb(248, 248, 248);
        border-top: 1px solid #eeeeee;
        width: calc(100% + 8px);
        padding: 7px 0;
      }

      .footer .text {
        padding-left: 8px;
        line-height: 35px;
      }

      .tableRowInActive {
        background: #F8F8F8;
        color: #BBBBBB;
      }
      .tableRowInActive.selected {
        background: #EBEBEB;
        color: #BBBBBB;
      }
      .tableRowInActive:hover {
        background: #F1F1F1;
      }

      .mapWrapper {
        height: calc(100% - 100px);
        overflow-y: auto;
      }
    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12" style="height: calc(100% - 100px);">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="ExportButton">Export</button>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div class="flex-child">
      <div id="timeline" style="height: 45%; display: none"></div>
      <div id="timeline_disabled" style="height: 45%;" class="columns col-12">
        <div style="margin: auto; text-align: center; color:lightgray;">
          <h5>Service Not Running</h5>
        </div>
      </div> 
    
      <div class="columns" style="height: 36px">
        <div style="text-align: center;" class="col-2">
          <button class="btn btn-primary" id="btn_reset_view" disabled>Reset view</button>
        </div>
        <div style="text-align: center;" class="col-4">
          <button class="btn btn-primary col-10" id="btn_add_planning_routes">Add Planning From Existing Routes</button>
        </div>
        <div style="text-align: center;" class="col-4">
          <button class="btn btn-primary col-10" id="btn_add_planning_routearea">Add Planning From RouteArea</button>
        </div>
        <div style="text-align: center;" class="col-2">
          <button class="btn btn-primary" id="btn_add_break">Add Break</button>
        </div>
      </div>

      <div id="planning_info" class="columns" style="height: calc(55% - 36px)">
        <div class="col-6 columns">
          
          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_device">Device</h6>
            <h9 id="info_planning_device">DEVICE</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_name">Route Name</h6>
            <h9 id="info_planning_name">ROUTENAME</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_distance">Route distance</h6>
            <h9 id="info_planning_distance">DISTANCE ROUTE</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_date">DATE</h6>
            <h9 id="info_planning_date">DATE</h9>
          </div>          

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_from">From</h6>
            <h9 id="info_planning_from">FROM TIME</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_to">To</h6>
            <h9 id="info_planning_to">TO TIME</h9>
          </div>          

          <div class="col-1" style="text-align: center; margin-top: 30px;">
          </div>

          <div class="col-2" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_info">Info</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_info" class="btn btn-primary">
              <i class="fa fa-info" aria-hidden="true"></i>
            </button> 
          </div>

          <div class="col-2" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_edit">Edit</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_edit" class="btn btn-orange">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </button>  
          </div>

          <div class="col-2" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_edit">Copy</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_copy" class="btn btn-orange">
              <i class="fa fa-copy" aria-hidden="true"></i>
            </button>  
          </div>

          <div class="col-2" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_send">Resend</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_send" class="btn btn-success">
              <i class="fa fa-share-square" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-2" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_delete">Delete</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_delete" class="btn btn-error">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>  
          </div>

          <div class="col-1" style="text-align: center; margin-top: 30px;">
          </div>

        </div>
        
        <div class="col-6 columns">
          <div class="mapWrapper" style="height: 100%">
            <div id="planning_map" class="aci-map"></div>
            <div class="legend legend-opaque" id="planning_map_legend">
            <div class="legend-label label-height-lg hidden">Legend</div>
            
            <div class="legend-item">
              <div class="route-preview" style="background-color: rgba(255, 0, 0, 0.7); border-color: #a30000"></div>
              <span>With Parking</span>
            </div>
            <div class="legend-item">
              <div class="route-preview" style="background-color: rgba(255, 143, 15, 0.7); border-color: #e08722"></div>
              <span>Without Parking</span>
            </div>
          </div>
          </div>
        </div>        
      </div>
    </div>
  `);
}
